/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { defineStore } from 'pinia';
import { computed, Ref, ref, watch } from 'vue';
import { useAppStore } from '@/store/app';
import { useAuthStore } from '@/store/auth';
import apiRequest from '@/services/apiRequest';
import { DateTime, Duration, DurationLike } from 'luxon';
import * as signalR from '@microsoft/signalr';
import { sleep } from '@/utils/promise-utils';
import { HasSequence } from '@/models/feed-entry';
import { isMatchEvent, type TimelineEntry } from '@/models/timeline';
import type { MatchEventCard, MatchEventType } from '@/models/match-event-card';

const LOOKBACK_DAYS = 7;
const POLL_INTERVAL = { seconds: 10 } satisfies DurationLike;

type Timeline = Array<TimelineEntry>;

export const getDateKey = (dt: DateTime) => dt.startOf('day').toFormat('yyyy-MM-dd');
export const getDateFromKey = (key: string) => DateTime.fromFormat(key, 'yyyy-MM-dd').startOf('day');

const timelineItemCompareFn = (a: HasSequence, b: HasSequence) => a.sequence - b.sequence;

export const triggerLoad = async () => await useTimelineStore().fetchData();

const fetchTimelineForDate = async (date: DateTime, timelineUid: string) => {
  const response = await apiRequest.methods.get(
    `summaries/day?timeline=${timelineUid}&day=${date.toFormat('yyyy-MM-dd')}`,
    'timelines',
  );
  if (!response.data) {
    throw Error(`No data received for timeline ${timelineUid} and date ${date.toFormat('yyyy-MM-dd')}`);
  }
  if (!(response.data instanceof Array)) {
    throw Error(
      `Invalid data received for timeline ${timelineUid} and date ${date.toFormat('yyyy-MM-dd')}: ${response.data}`,
    );
  }
  return (response.data as Timeline).sort(timelineItemCompareFn);
};

export const useTimelineStore = defineStore('timeline', () => {
  const startDate = ref(DateTime.utc().startOf('day'));

  const timelinesByDay = ref<Map<string, Timeline>>(new Map<string, Timeline>());

  const selectedTimelineUid = computed(() => useAuthStore().selectedTimelineUid);

  const connection = ref(undefined) as Ref<signalR.HubConnection | undefined>;

  const isConnected = ref(false);

  const dataFetchingError = ref<unknown>();
  const isDone = ref(false);
  const isLoading = ref(false);

  const fetchData = async () => {
    if (!selectedTimelineUid.value) {
      return;
    }
    isLoading.value = true;
    const promises: Promise<any>[] = [];
    for (
      let date = startDate.value;
      date >= startDate.value.minus({ days: LOOKBACK_DAYS });
      date = date.minus({ days: 1 })
    ) {
      promises.push(
        (async () =>
          timelinesByDay.value.set(getDateKey(date), await fetchTimelineForDate(date, selectedTimelineUid.value)))(),
      );
    }

    try {
      await Promise.all(promises);
      dataFetchingError.value = undefined;
    } catch (err: unknown) {
      dataFetchingError.value = err;
    } finally {
      isDone.value = true;
      isLoading.value = false;
    }
  };

  watch(selectedTimelineUid, async (timelineUid, previous) => {
    if (previous === timelineUid || timelineUid === undefined || timelineUid.trim() === '') {
      return;
    }

    // Reset
    timelinesByDay.value = new Map<string, Timeline>();

    const establishWsConnection = async () => {
      if (connection.value) {
        console.log('Verbindung besteht bereits');
        return;
      }

      connection.value = new signalR.HubConnectionBuilder()
        .withUrl(`https://timelines-${useAppStore().environment}.leagues.network/hubs/summaries`)
        .configureLogging(signalR.LogLevel.Information)
        .build();

      try {
        await connection.value.start();

        await connection.value?.send('ConnectTimeline', timelineUid);

        connection.value.on('TimelineConnected', (timelineId) => {
          isConnected.value = true;
          console.log(`WebSocket connection to timeline '${timelineId}' established.`);
        });

        connection.value.on('ItemAddedToTimelineEvent', async (timelineEntry: TimelineEntry) => {
          console.log('ItemAddedToTimelineEvent', timelineEntry);

          const date = DateTime.fromISO(timelineEntry.moment);
          await fetchTimelineForDate(date, timelineUid);

          // TODO(#833): The received payload is buggy.
          // const date = DateTime.fromISO(timelineEntry.moment);
          // const dateKey = getDateKey(date);
          // const currentEvents = timelinesByDay.value.get(dateKey) || [];
          //
          // // Überprüfe, ob matchEvent.id bereits vorhanden ist
          // const isEventAlreadyAdded = currentEvents.some((event) => event.id === timelineEntry.id);
          //
          // if (!isEventAlreadyAdded) {
          //   currentEvents.push(timelineEntry);
          //   timelinesByDay.value.set(dateKey, currentEvents);
          // }
        });

        connection.value.on('TimelineItemUpdatedEvent', async (timelineEntry: TimelineEntry) => {
          console.log('TimelineItemUpdatedEvent', timelineEntry);
          const date = DateTime.fromISO(timelineEntry.moment);

          // TODO: The match events sent by the Timelines websocket connection contain invalid `elements`, so we just
          // fetch and replace data for that entire day again. The code below can be used once that issue is resolved.
          timelinesByDay.value.set(getDateKey(date), await fetchTimelineForDate(date, timelineUid));

          // const dayEvents = timelinesByDay.value.get(getDateKey(forDate));
          //
          // if (dayEvents) {
          //   const existing = dayEvents.find((x) => x.id === matchEvent.id);
          //
          //   if (existing) {
          //     // Replace existing item
          //     dayEvents.splice(dayEvents.indexOf(existing), 1, matchEvent);
          //   } else {
          //     dayEvents.push(matchEvent);
          //     dayEvents.sort(timelineItemCompareFn); // in-place
          //   }
          // } else {
          //   timelinesByDay.value.set(getDateKey(forDate), [matchEvent]);
          // }
        });

        connection.value.on('TimelineItemRemovedEvent', async (timelineEntry: TimelineEntry) => {
          const date = DateTime.fromISO(timelineEntry.moment);
          const current = timelinesByDay.value.get(getDateKey(date));
          current?.splice(
            current.findIndex((x) => x.id === timelineEntry.id),
            1,
          );
        });
      } catch (ex) {
        connection.value = undefined;
        console.error('Unable to establish websocket connection. Trying again in 60 seconds.', ex);
        setTimeout(establishWsConnection, 60000);
      }
    };

    await Promise.all([fetchData(), establishWsConnection()]);
  });

  const pollingAbortController = ref(undefined) as Ref<AbortController | undefined>;
  function startPolling() {
    if (pollingAbortController.value) {
      if (pollingAbortController.value?.signal.aborted) {
        // Reset and let the other function continue
        pollingAbortController.value = new AbortController();
      }
      return;
    }

    pollingAbortController.value = new AbortController();

    async function fetchInLoop() {
      await sleep(Duration.fromDurationLike(POLL_INTERVAL).toMillis());

      if (pollingAbortController.value?.signal.aborted) {
        pollingAbortController.value = undefined;
        return;
      }

      if (!selectedTimelineUid.value) {
        await fetchInLoop();
        return;
      }

      try {
        timelinesByDay.value.set(
          getDateKey(startDate.value),
          await fetchTimelineForDate(startDate.value, selectedTimelineUid.value),
        );
      } catch (err) {
        console.error('Fehler beim polling von Timeline:', err);
      }

      await fetchInLoop();
    }

    fetchInLoop();
  }

  function stopPolling() {
    pollingAbortController.value?.abort('stopPolling called');
  }

  watch(startDate, async (newValue, prev) => {
    if (!selectedTimelineUid.value) {
      return;
    }
    if (newValue && newValue !== prev) {
      timelinesByDay.value.clear();
      isDone.value = false;
      stopPolling();
      await fetchData();
      startPolling();
    }
  });

  function findKeyContainingCard(cardId: string) {
    for (const [key, items] of timelinesByDay.value.entries()) {
      for (const card of items) {
        if (card.id === cardId) {
          return key;
        }
      }
    }
    return undefined;
  }

  /**
   * TODO: Once the signalR notifications work reliably, this function can be turned into a no-op.
   */
  async function invalidateCard(cardId: string) {
    const key = findKeyContainingCard(cardId);
    if (!key) {
      return;
    }

    const res = await fetchTimelineForDate(getDateFromKey(key), selectedTimelineUid.value);
    timelinesByDay.value.set(key, res);
  }

  function getMostRecentMatchEvent({
    eventTypesFilter: eventTypesFilter = undefined,
  }: {
    eventTypesFilter?: MatchEventType[];
  } = {}) {
    const now = DateTime.utc();
    const eventsToday = timelinesByDay.value.get(getDateKey(now)) ?? [];

    return eventsToday.find(
      (card) =>
        // Only match events (not news, not media posts)
        isMatchEvent(card) &&
        // If event types specified, only those events
        (!eventTypesFilter || eventTypesFilter.includes(card.event)),
    ) as MatchEventCard | undefined;
  }

  return {
    timelinesByDay,
    selectedTimelineUid,
    ...({ isDone, isConnected } as const),
    dataFetchingError,
    fetchData,
    isLoading,
    startPolling,
    stopPolling,
    startDate,
    invalidateCard,
    getMostRecentMatchEvent,
  };
});
