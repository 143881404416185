import { getDateKey, useTimelineStore } from '@/store/timeline';
import { DateTime } from 'luxon';
import { computed } from 'vue';
import { isMatchEvent } from '@/models/timeline';
import type { MediaPostCard } from '@/models/media-post-card';
import type { MatchEventCard } from '@/models/match-event-card';
import type { NewsPostCard } from '@/models/news-post-card';
import apiRequest from '@/services/apiRequest';
import { mediaTypeTranslation } from '@/models/media-element';

export default function useCardMerging(cardToMerge: MediaPostCard) {
  const targetFeedEntries = computed(() => {
    const key = getDateKey(DateTime.fromISO(cardToMerge.moment));
    const entries = useTimelineStore().timelinesByDay.get(key);
    return entries?.filter(isMatchEvent) ?? [];
  });

  async function mergeWithCard(
    targetCard: MatchEventCard | NewsPostCard,
    { progressCallback }: { progressCallback?: (stepDescription: string) => void } = {},
  ) {
    for (const element of cardToMerge.elements) {
      if (!['Video', 'Audio', 'Image'].includes(element.$type)) continue;
      const payload = {
        elements: [element],
      };
      progressCallback?.(`Verschiebe eine ${mediaTypeTranslation(payload.elements[0].$type)}datei...`);
      await apiRequest.methods.post(`api/timelines/addCardSegment?card=${targetCard.id}`, 'cloud', payload);
    }

    for (const segment of cardToMerge.segments ?? []) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars -- Remove order from segment to have it be auto-determined by the server
      const { order: _, ...payload } = segment;
      progressCallback?.(`Verschiebe eine ${mediaTypeTranslation(payload.elements[0].$type)}datei...`);
      await apiRequest.methods.post(`api/timelines/addCardSegment?card=${targetCard.id}`, 'cloud', payload);
    }

    progressCallback?.('Lösche die alte Karte...');
    await apiRequest.methods.delete(`api/timelines/removeCard?id=${cardToMerge.id}`, 'cloud');

    useTimelineStore().invalidateCard(targetCard.id);
  }

  return { targetFeedEntries, mergeWithCard } as const;
}
