<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount, Ref } from 'vue';
import { emitter } from '@/bus';
import * as signalR from '@microsoft/signalr';
import SuperSupProposals from '@/components/superSub/SuperSubProposals.vue';
import { useAuthStore } from '@/store/auth';
import { useAppStore } from '@/store/app';
import apiRequest from '@/services/apiRequest';
let connection: signalR.HubConnection;

const myClub = useAuthStore().userSettings?.selectedTimeline?.uid;
const fixtureUid = ref('');
const matchPerspective = ref('');
const props = defineProps<{
  eventEntityId?: string;
  guestId?: string | number;
  homeId?: string | number;
  teamId?: string | number;
}>();
const eventInfos = async () => {
  if (props.eventEntityId) {
    const request = await apiRequest.methods.get(`api/data/matchevents/${props.eventEntityId}`, 'cloud');
    fixtureUid.value = request.data.fixtureUid;
  }

  if (props.guestId === myClub) {
    matchPerspective.value = 'guest';
  } else if (props.homeId === myClub || (props.teamId && props.teamId == myClub)) {
    matchPerspective.value = 'home';
  } else {
    matchPerspective.value = 'guest';
  }
};

onMounted(async () => {
  await eventInfos();
  await startConnection();
  emitter.on('superSubMatchReporter', (speechHint) => sendCaptionMatchEvent(speechHint));
});
onBeforeUnmount(() => {
  stopConnection();
  emitter.all.delete('superSubMatchReporter');
});
const emit = defineEmits(['activateSuperSubMatchReporter', 'chosenPhrase']);

const superSupActivate = ref(false) as Ref<boolean>;
const receivedMessages = ref([]) as Ref<Array<any>>;
/*
watch(receivedMessages, (newVal) => {
  emit('activateSuperSubMatchReporter', true);
}, { deep: true });
*/
const sendCaptionMatchEvent = async (speechHint: string) => {
  superSupActivate.value = true;
  emit('activateSuperSubMatchReporter', true);
  if (connection && connection.state === signalR.HubConnectionState.Connected) {
    // Sende die Initialisierungsnachricht
    try {
      await connection.send(
        JSON.stringify({
          protocol: 'json',
          version: 1,
        }),
      );
    } catch (err) {
      console.log(`Fehler beim Senden der Initialisierungsnachricht: ${err}`);
      return;
    }

    const fixtureId = fixtureUid.value;
    const matcheventId = props.eventEntityId;
    const MatchPerspective = matchPerspective.value;

    try {
      await connection.invoke('CaptionMatchEvent', fixtureId, matcheventId, MatchPerspective, speechHint);
    } catch (err) {
      console.log(`Fehler beim Senden des CaptionMatchEvent: ${err}`);
    }
  } else {
    console.log('Keine aktive Verbindung vorhanden');
  }
};

const chosenPhrase = (phrase: string) => emit('chosenPhrase', phrase);

const startConnection = async () => {
  if (connection && connection.state === signalR.HubConnectionState.Connected) {
    console.log('Verbindung besteht bereits');
    return;
  }

  connection = new signalR.HubConnectionBuilder()
    .withUrl(`https://supersub-${useAppStore().environment}.leagues.network/hubs/matchreporter`)
    .configureLogging(signalR.LogLevel.Debug)
    .build();

  connection
    .start()
    .then(() => connection.on('Caption', (message) => receivedMessages.value.push(message)))
    .catch((err) => console.log(`Error: ${err}`));
};

const updateProposals = (updatedObj: { current: number; text: string }) => {
  receivedMessages.value[updatedObj.current] = updatedObj.text;
};

const stopConnection = () => {
  if (connection) {
    connection
      .stop()
      .then(() => {
        console.log('SignalR connection stopped');
      })
      .catch((err: any) => {
        console.log('Fehler beim Beenden der Verbindung: ', err);
      });
  }
};
</script>

<template>
  <super-sup-proposals
    v-if="superSupActivate"
    :proposals="receivedMessages"
    @chosenPhrase="chosenPhrase"
    @update:proposals="updateProposals"
  />
  <div v-if="!superSupActivate">
    <slot></slot>
  </div>
</template>
