<script setup lang="ts">
import type { FileWithKind, PickedFile } from '@/services/filePicker/models';
import { FileKind, ThumbnailURL } from '@/services/filePicker/models';
import filePicker from '@/services/filePicker';
import { computed, Ref, ref, watchEffect, onErrorCaptured, watch } from 'vue';
import { IonSpinner } from '@ionic/vue';
import { ALL_MIME_TYPES, getFileKindFromMimeType } from '@/services/filePicker/mime-types';
import formatBytes from '@/components/uploadProgress/formatBytes';
import helper from '@/services/helper';
import { Capacitor } from '@capacitor/core';
import CloudFileGallery from '@/components/CloudFileGallery.vue';
import { CloudFile } from '@/views/cloud-page/CloudFile';
import { useResizeObserver } from '@vueuse/core';
import uploadFilesV2 from '@/services/uploadFilesV2';

const props = withDefaults(
  defineProps<{ showForm?: boolean; allowedFileKind?: FileKind; canUploadFromCloud?: boolean }>(),
  {
    showForm: true,
    allowedFileKind: 'all',
    canUploadFromCloud: true,
  },
);

const error = ref<Error | null>(null);

onErrorCaptured((err) => {
  error.value = err;
  console.error(err);
});

const emit = defineEmits<{
  (e: 'fileSelected', file: FileWithKind, thumbnail: ThumbnailURL): unknown;
  (e: 'cloudFileSelected', file: CloudFile, thumbnail: ThumbnailURL): unknown;
  (e: 'cloudFilePickerActive', value: boolean): void;
}>();

const isNative = Capacitor.isNativePlatform();

const selectedFile = ref<PickedFile | undefined>(undefined);
const selectedCloudFile = ref<CloudFile | undefined>(undefined);

const thumbnail = ref(null) as Ref<ThumbnailURL | null>;
const canvas = ref<HTMLCanvasElement>();
const hasPicked = ref(false);
const isLoading = ref(false);

const isDragging = ref(false);
const isOver = ref(false);

const showCloudFilePicker = ref(false);

watchEffect(() => {
  if (!error.value) {
    return;
  }

  selectedFile.value = undefined;
  selectedCloudFile.value = undefined;
  thumbnail.value = null;
  hasPicked.value = false;
  showCloudFilePicker.value = false;
  isLoading.value = false;
});

const dropzoneCaption = computed(() => {
  switch (props.allowedFileKind) {
    case 'all':
      return 'Zieh ein Foto oder Video hierher, um es hochzuladen';
    case 'video':
      return 'Zieh ein Video hierher, um es hochzuladen';
    case 'audio':
      return 'Zieh eine Audiodatei hierher, um sie hochzuladen';
    case 'image':
      return 'Zieh ein Bild hierher, um es hochzuladen';
    default:
      throw Error(`Unknown file kind: ${props.allowedFileKind}`);
  }
});

const uploadButtonCaption = computed(() => {
  switch (props.allowedFileKind) {
    case 'all':
      return 'Upload vom Gerät';
    case 'video':
      return 'Video-Upload vom Gerät';
    case 'audio':
      return 'Audio-Upload vom Gerät';
    case 'image':
      return 'Bild-Upload vom Gerät';
    default:
      throw Error(`Unknown file kind: ${props.allowedFileKind}`);
  }
});

function onFileSelected(f: PickedFile) {
  error.value = null;

  const [isValid, validationMessage] = uploadFilesV2.validateFileToUpload(f);

  if (!isValid) {
    helper.methods.microInteraction(validationMessage);
    return;
  }

  hasPicked.value = true;
  selectedFile.value = f;

  isLoading.value = true;

  filePicker
    .generateThumbnailURL(selectedFile.value)
    .then((result) => (thumbnail.value = result))
    .catch((err) => {
      console.error(err);
      return (error.value = err);
    });
}

function onCloudFileSelected(file: CloudFile, thumbnailURL: string) {
  hasPicked.value = true;
  selectedCloudFile.value = file;
  thumbnail.value = thumbnailURL;
  showCloudFilePicker.value = false;
}

const onDrop = async (ev: DragEvent) => {
  ev.preventDefault();
  const files = ev.dataTransfer?.files;
  if (!files || files.length !== 1) {
    helper.methods.microInteraction('Bitte lade nur eine Datei hoch.');
    return;
  }

  const f = files[0];

  onFileSelected({
    ...f,
    mimeType: f.type,
    blob: f,
    modifiedAt: f.lastModified,
  });
};

const handleChooseFileClick = async () => {
  const result = await filePicker.pickSingleFile(props.allowedFileKind);
  if (!result) {
    // Only override the refs file if the file picker reported a change (and not undefined, which may happen when
    // the dialog is canceled).
    return;
  }

  onFileSelected(result);
};

const fileKind = computed(() =>
  selectedFile.value
    ? getFileKindFromMimeType(selectedFile.value.mimeType)
    : selectedCloudFile.value
      ? getFileKindFromMimeType(selectedCloudFile.value.contentType)
      : undefined,
);
const unknownFileType = computed(() => selectedFile.value && !fileKind.value);

const thumbnailImageStyle = computed(() => (thumbnail.value ? `url(${thumbnail.value})` : null));

const toggleShowCloudFilePicker = () => {
  const newValue = !showCloudFilePicker.value;
  showCloudFilePicker.value = newValue;
  emit('cloudFilePickerActive', newValue);
};

watch(thumbnail, (thumb) => {
  // Once the thumbnail changes, we know the selectedFile has also been set
  if (!selectedFile.value && !selectedCloudFile.value) {
    return;
  }
  if (!thumb || !fileKind.value || unknownFileType.value) {
    return;
  }

  isLoading.value = false;

  if (selectedFile.value) {
    const fileWithKind = { ...selectedFile.value, kind: fileKind.value } satisfies FileWithKind;
    emit('fileSelected', fileWithKind, thumb);
  } else if (selectedCloudFile.value) {
    emit('cloudFileSelected', selectedCloudFile.value, thumb);
  }
});

watch(unknownFileType, (error) => {
  if (error && selectedFile.value) {
    helper.methods.microInteraction(
      `Wir können mit dem Dateityp ${selectedFile.value.mimeType} leider nicht umgehen.\n` +
        `Die Folgenden Dateitypen sind unterstützt: ${ALL_MIME_TYPES.join(', ')}`,
    );
  }
});

const containerRef = ref<HTMLDivElement>();
const containerMinimumHeight = ref(0);

useResizeObserver(containerRef, (entries) => {
  const entry = entries[0];
  const { height } = entry.contentRect;

  if (height > containerMinimumHeight.value) {
    containerMinimumHeight.value = height;
  }
});
</script>

<template>
  <div ref="containerRef" :style="{ minHeight: `${containerMinimumHeight}px !important` }">
    <div v-if="isLoading" class="h-full w-full flex items-center justify-center place-content-center">
      <ion-spinner v-if="isLoading" class="upload-spinner inline-block mt-8" color="black" name="dots" />
    </div>
    <template v-else>
      <div v-if="thumbnailImageStyle" class="thumbnail" :style="{ backgroundImage: thumbnailImageStyle ?? 'none' }" />
      <div v-if="selectedFile || selectedCloudFile" class="mb-2">
        <canvas ref="canvas" class="hidden"></canvas>
        <div class="upload-info-container" v-if="selectedFile?.name || selectedCloudFile?.fileName">
          <div class="bold block text-sm uploadInfo">
            <b>
              {{ selectedFile?.name || selectedCloudFile?.fileName }}
            </b>
          </div>
          <span class="uploadInfo opacity-80" v-if="selectedFile && selectedFile.size">
            {{ formatBytes(selectedFile.size, 1) }}
          </span>
        </div>
      </div>

      <template v-if="showCloudFilePicker">
        <div class="h-full cloud-container">
          <div class="flex flex-col mb-4 h-full">
            <cloud-file-gallery
              :allowed-file-kind="allowedFileKind"
              :select-mode="true"
              @file-selected="onCloudFileSelected"
            />
          </div>
        </div>
      </template>

      <template v-else>
        <template v-if="isNative">
          <div class="flex upload mb-1 mt-2 text-center flex-col gap-4">
            <button class="default-button" @click="handleChooseFileClick">
              <span>{{ uploadButtonCaption }}</span>
            </button>
            <button
              v-if="canUploadFromCloud"
              class="default-button default-button-grey"
              @click="toggleShowCloudFilePicker"
            >
              <span>Aus der Leagues-Cloud</span>
            </button>
          </div>
        </template>

        <template v-else>
          <div :class="{ none: hasPicked }" class="file-picker">
            <label
              :class="{ dragging: isDragging }"
              @dragover.prevent="isDragging = true"
              @dragover="isOver = true"
              @dragleave="
                isDragging = false;
                isOver = false;
              "
              @drop="
                onDrop($event);
                isDragging = false;
                isOver = false;
              "
            >
              <div :class="{ over: isOver }" class="upload-area cursor-pointer">
                <div
                  :class="[
                    'wrapper',
                    {
                      'opacity-20': !selectedFile && hasPicked,
                      hidden: !showForm,
                    },
                  ]"
                >
                  <div class="area-label">
                    <span class="main-label">{{ dropzoneCaption }}</span>
                  </div>
                </div>
              </div>
            </label>
            <div class="flex justify-center gap-4 flex-wrap">
              <button class="default-button inline-block" @click="handleChooseFileClick">
                {{ uploadButtonCaption }}
              </button>
              <button
                v-if="canUploadFromCloud"
                class="default-button default-button-grey"
                @click="toggleShowCloudFilePicker"
              >
                <span>Aus der Leagues-Cloud</span>
              </button>
            </div>
          </div>
        </template>

        <div v-if="selectedFile && !fileKind" class="error">
          Wir können mit diesem Dateityp {{ selectedFile.mimeType ? `('${selectedFile.mimeType}')` : '' }} leider nicht
          umgehen.
        </div>

        <div v-if="error" class="error">
          {{ error.message ? `Fehler: ${error.message}` : 'Ein unbekannter Fehler ist aufgetreten.' }}
        </div>
      </template>
    </template>
  </div>
</template>

<style scoped lang="scss">
$gallery-gap: 2px;

.leagues-cloud {
  background: linear-gradient(180deg, #00d4d6 0%, #00a0a1 100%) !important;
}

.error {
  color: var(--ion-color-danger) !important;
  margin-top: 1rem;
}

.cloud-container {
  .file-picker {
    display: block !important;
  }
}

.dual-pill-button {
  .upload {
    flex-direction: column;
    gap: 0.5rem;

    span {
      line-height: 1;
      font-weight: 600;
      font-size: 1.2rem;
      text-transform: uppercase;
    }

    label,
    button {
      padding: 0 !important;
      display: flex;
      border-right: 1px solid var(--ion-tab-bar-background, #1f1f1f);
      background: linear-gradient(180deg, #4f4f4f 0%, #393939 100%);
      border-radius: 24px;
      gap: 5px;
      box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
    }
    /*label {
      &:nth-of-type(1) {
        border-radius: 24px 0 0 24px;
      }
    }*/

    button {
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 1rem 0.5rem !important;

      ion-icon {
        margin-bottom: 0;
        font-size: 2rem;
      }
      /*&:nth-of-type(1) {
        border-radius: 24px 0 0 24px;
      }

      &:nth-last-of-type(1) {
        border-radius: 0 24px 24px 0;
      }*/
    }

    .icon-wrapper {
      padding: 2px;
    }
  }

  .app-icon {
    fill: #fff;
  }
}

.upload-info-container {
  text-align: left;
  line-height: 1;

  .uploadInfo {
    font-size: 14px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.7;
  }
}

.upload-area {
  padding: 2rem;
  border-style: dashed;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.2392156863);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: 0.3s ease-in-out;
  flex-direction: column;
  margin-bottom: 2rem;
  margin-top: 1rem;

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .area-label {
      display: flex;
      flex-direction: column;
      align-items: center;

      .main-label {
        font-weight: 600;
        font-size: 1.75rem;
        text-align: center;
      }
    }

    ion-icon {
      transition: 0.3s ease-in-out;
      color: var(--ion-tab-bar-background, #1f1f1f) !important;
    }
  }
}

.dragging,
.over {
  .wrapper {
    ion-icon {
      animation: light-pulse 1s infinite;
      color: #00d4d6 !important;
    }
  }
}

.dragging {
  .upload-area {
    border-color: var(--ion-color-primary, #3880ff);
  }
}

.over.upload-area {
  border-color: var(--ion-color-primary, #3880ff);
}

.default-button-grey {
  background: #454545 !important;
}
</style>
