<script lang="ts" setup>
import { close } from 'ionicons/icons';
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonModal, IonTitle, IonToolbar } from '@ionic/vue';
import { ref, watch } from 'vue';
import useCardMerging from '@/views/feed/feed-entry-details/useCardMerging';
import type { MediaPostCard } from '@/models/media-post-card';
import { formatGameMinute } from '@leagues-football/creator-app/src/models/game-timing';
import MatchEventPictogram from '@/views/feed/feed-entries/MatchEventPictogram.vue';
import type { MatchEventCard, MatchEventCardId } from '@/models/match-event-card';

const modal = ref<{ $el: HTMLIonModalElement }>();

const props = defineProps<{ originalCard: MediaPostCard }>();

const emit = defineEmits<{ (e: 'cardsMerged', MatchEventCardId: MatchEventCardId): void; (e: 'close'): void }>();

const selectedTargetCard = ref<MatchEventCard>();

const selectCard = (card: MatchEventCard) => {
  selectedTargetCard.value = card;
};

const { targetFeedEntries, mergeWithCard } = useCardMerging(props.originalCard);

const progressSteps = ref<string[]>([]);
watch(
  () => props.originalCard.id,
  () => {
    progressSteps.value = [];
  },
);

const mergeCards = async () => {
  if (!selectedTargetCard.value) return;

  await mergeWithCard(selectedTargetCard.value, { progressCallback: (step) => progressSteps.value.push(step) });

  closeModal();
  emit('cardsMerged', selectedTargetCard.value.id);
};

const closeModal = () => modal.value?.$el.dismiss();
</script>

<template>
  <ion-modal trigger="merge-card-modal-trigger" ref="modal">
    <ion-header>
      <ion-toolbar>
        <ion-title> Card zusammenfügen</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="closeModal()">
            <ion-icon :icon="close"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div class="flex flex-col m-4">
        <div class="w-full" v-if="progressSteps.length > 0">
          <span v-for="step in progressSteps" :key="step" class="text-center w-full block">
            {{ step }}
          </span>
        </div>

        <template v-else>
          <div
            class="match-event-card"
            v-for="card in targetFeedEntries"
            :key="card.id"
            :class="{ active: selectedTargetCard?.id === card.id }"
            @click="selectCard(card)"
          >
            <div class="match-event-card-time">{{ formatGameMinute(card) }}</div>
            <match-event-pictogram
              :event-type="card.event"
              :element="card.elements[0]"
              class="match-event-card-label"
            />
          </div>

          <div class="mt-auto mb-4">
            <ion-button fill="solid" :disabled="!selectedTargetCard" @click="mergeCards" class="default-button mx-auto">
              Zusammenfügen
            </ion-button>
          </div>
        </template>
      </div>
    </ion-content>
  </ion-modal>
</template>

<style lang="scss" scoped>
.match-event-card {
  display: flex;
  background: #191e24;
  border-radius: 0.5rem;
  overflow: hidden;
  margin-bottom: 1rem;
  font-weight: 600;
  min-height: 72px;

  @media (min-width: 769px) {
    &:hover {
      box-shadow: 0px 0px 0px 1px #0ff;
    }
  }

  .match-event-card-time {
    padding: 1rem 0.75rem;
    min-width: 72px;
    font-weight: 800;
    background: #373737;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    filter: contrast(0.8);
  }

  .match-event-card-label {
    display: flex;
    align-items: center;
    padding: 0 0.7rem;
    width: 100%;
    gap: 0.5rem;

    .icon {
      color: #fff;
      text-transform: uppercase;
      border-radius: 4px;
      line-height: 1.2;
      display: flex;
      gap: 5px;
      align-items: center;

      .app-icon {
        width: 24px;
      }

      &.yellow-card {
        fill: #ffff00;
      }

      &.red-card {
        fill: #ff0000;
      }

      &.lightgreen {
        fill: lightgreen;
      }
    }

    ion-icon {
      width: 24px;
    }
  }
}

.active {
  box-shadow: 0 0 0 1px #0ff;
}

.default-button {
  padding: 0 !important;
  width: 100%;
  max-width: 300px;
}

ion-label {
  font-weight: normal !important;
}
</style>
