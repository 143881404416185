import { defineStore } from 'pinia';
import { ref } from 'vue';

import { ThumbnailURL } from '@/services/filePicker/models';

type TaskID = string;

export const usePublishQueueAssets = defineStore('uploadQueueAssets', () => {
  const uploadTaskThumbnails = ref(new Map<TaskID, null | ThumbnailURL>());

  return { uploadTaskThumbnails };
});
