<script lang="ts" setup>
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useAuthStore } from '@/store/auth';
import CloudService from '@/services/cloud';
import { IonButton, IonList, IonModal, modalController } from '@ionic/vue';
import { Skeletor } from 'vue-skeletor';
import { emitter } from '@/bus';
import CloudFilePreviewDrawer from '@/views/cloud-page/CloudFilePreview.vue';
import { CloudFile } from '@/views/cloud-page/CloudFile';
import { getFileKindFromMimeType } from '@/services/filePicker/mime-types';
import { FileKind } from '@/services/filePicker/models';

const props = withDefaults(defineProps<{ selectMode?: boolean; allowedFileKind?: FileKind }>(), {
  selectMode: false,
  allowedFileKind: 'all',
});

const emit = defineEmits<{ (e: 'fileSelected', cloudFile: CloudFile, thumbnailURL: string): unknown }>();

const possibleFiles = ref([] as CloudFile[]);

const fileKindFilter = ref<FileKind>(props.allowedFileKind);

const shownFiles = computed(() =>
  fileKindFilter.value === 'all'
    ? possibleFiles.value
    : possibleFiles.value.filter((x) => getFileKindFromMimeType(x.contentType) === fileKindFilter.value),
);
const numFilesShown = computed(() => shownFiles.value.length);

const filesLoaded = ref(false);
const isOver = ref(false);

const inboxId = computed(() => useAuthStore().selectedTimelineUid);

const drag = ref<HTMLLabelElement>();

onMounted(() => {
  drag.value?.addEventListener('dragover', () => {
    isOver.value = true;
  });
  drag.value?.addEventListener('dragleave', () => {
    isOver.value = false;
  });

  emitter.on('refreshPage', resetView);
});

function addFiles(files: CloudFile[], _: number) {
  if (!inboxId.value) {
    return;
  }
  possibleFiles.value = addFileUrl(files, inboxId.value);
}

function addFileUrl(files: CloudFile[], inboxId: string) {
  const result = [] as CloudFile[];
  if (files.length) {
    files.forEach((entry) => result.unshift(entry));
    result.forEach((file) => {
      file.url = 'https://cdn.leagues.network/inbox/' + inboxId + '/' + file.fileName;
    });
  }
  return result;
}

// function infiniteScroll(e: any) {
//   // This doesn't appear to ever be called because we don't actually limit the number of items...
//   showMoreFiles(CloudService.calculateNumColumns());
//   setTimeout(() => e.target.complete(), 500);
// }

async function resetView() {
  if (!inboxId.value) {
    return;
  }
  possibleFiles.value = [];
  filesLoaded.value = false;
  const files = await CloudService.getFiles(inboxId.value);
  filesLoaded.value = true;
  if (files) {
    addFiles(files.entries, CloudService.calculateNumColumns());
  }
}

const fileKindName = computed(() => {
  switch (fileKindFilter.value) {
    case 'all':
      return 'Dateien';
    case 'video':
      return 'Videos';
    case 'audio':
      return 'Audios';
    case 'image':
      return 'Bilder';
    default:
      throw Error(`Unknown file kind: ${fileKindFilter.value}`);
  }
});

const getCloudURL = (fileName: string) => `https://cdn.leagues.network/inbox/${inboxId.value}/${fileName}`;

const getThumbnailURL = (fileName: string, contentType: string) => {
  let image = '';
  if (contentType === 'video/mp4') {
    image = '.jpg';
  }
  return `${getCloudURL(fileName)}${image}`;
};

watch(
  inboxId,
  (newVal) => {
    if (newVal) {
      resetView();
    }
  },
  { immediate: true },
);

onBeforeUnmount(() => emitter.all.delete('refreshPage'));
</script>

<template>
  <div class="gallery-filter">
    <button
      v-if="allowedFileKind === 'all'"
      @click="fileKindFilter = 'all'"
      :class="{ active: fileKindFilter === 'all' }"
      type="button"
    >
      Alle
    </button>
    <button
      v-if="allowedFileKind === 'all' || allowedFileKind === 'image'"
      @click="fileKindFilter = 'image'"
      :class="{ active: fileKindFilter === 'image' }"
      type="button"
    >
      Bilder
    </button>
    <button
      v-if="allowedFileKind === 'all' || allowedFileKind === 'video'"
      @click="fileKindFilter = 'video'"
      :class="{ active: fileKindFilter === 'video' }"
      type="button"
    >
      Video
    </button>
  </div>
  <ion-list>
    <template v-if="numFilesShown > 0">
      <div class="file-viewer">
        <template v-for="(file, i) in shownFiles" :key="file.url">
          <div v-if="getFileKindFromMimeType(file.contentType) === 'image'" class="file-container">
            <img :src="file.url" :alt="file.fileName" />
            <ion-button :id="`open-modal-${i}`" expand="block">Open</ion-button>
          </div>
          <div v-else-if="getFileKindFromMimeType(file.contentType) === 'video'" class="file-container">
            <img :alt="file.fileName" :src="getThumbnailURL(file.fileName, file.contentType)" />
            <ion-button :id="`open-modal-${i}`" expand="block">Open</ion-button>
            <div class="file-label">
              <svg
                fill="#fff"
                x="0px"
                y="0px"
                width="163.861px"
                height="163.861px"
                viewBox="0 0 163.861 163.861"
                style="enable-background: new 0 0 163.861 163.861"
                xml:space="preserve"
              >
                <g>
                  <path
                    d="M34.857,3.613C20.084-4.861,8.107,2.081,8.107,19.106v125.637c0,17.042,11.977,23.975,26.75,15.509L144.67,97.275 c14.778-8.477,14.778-22.211,0-30.686L34.857,3.613z"
                  />
                </g>
              </svg>
            </div>
          </div>
          <ion-modal
            class="bottom-drawer"
            ref="modal"
            :breakpoints="[0, 0.25, 0.5, 0.75]"
            :initial-breakpoint="0.75"
            :trigger="`open-modal-${i}`"
          >
            <cloud-file-preview-drawer
              :file="file"
              @reset="resetView()"
              :selectMode="selectMode"
              @selected="
                (cloudFile) => {
                  emit('fileSelected', cloudFile, getThumbnailURL(file.fileName, file.contentType));
                  modalController.dismiss();
                }
              "
            />
          </ion-modal>
        </template>
      </div>
    </template>
    <template v-else-if="!filesLoaded">
      <div class="file-viewer">
        <template v-for="n in CloudService.calculateNumColumns()" :key="n">
          <div class="file-container">
            <skeletor height="100%" width="100%" />
          </div>
        </template>
      </div>
    </template>
    <template v-else>
      <h1 class="text-center text-xl w-full font-bold pt-2">Es stehen keine {{ fileKindName }} zur Verfügung</h1>
    </template>
  </ion-list>
  <!--  <ion-infinite-scroll @ionInfinite="infiniteScroll($event)">-->
  <!--    <ion-infinite-scroll-content></ion-infinite-scroll-content>-->
  <!--  </ion-infinite-scroll>-->
</template>

<style lang="scss">
$gallery-gap: 2px;

.file-container {
  @media (min-width: 480px) and (max-width: 767px) {
    width: calc(25% - #{$gallery-gap});
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: calc(20% - #{$gallery-gap});
  }

  @media (min-width: 1024px) and (max-width: 1199px) {
    width: calc(12.5% - #{$gallery-gap});
  }

  @media (min-width: 1200px) {
    width: calc(10% - #{$gallery-gap});
  }
}

.upload-area {
  margin-top: 0;
}

.add-file {
  label {
    padding: 0;
    border: none;
    margin: 0;
  }
}

.gallery-filter {
  margin-bottom: 1rem;
  display: flex;
  gap: 0.5rem;

  button {
    opacity: 0.5;
  }

  .active {
    opacity: 1;
  }
}

.file-viewer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: $gallery-gap;

  img,
  video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
    overflow: hidden;
  }
}

ion-list,
.list-md {
  background-color: transparent;
}

.inspect-file {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  img,
  video {
    max-width: 80%;
    max-height: 80%;
    margin: auto;

    @media (min-width: 768px) {
      max-width: 70%;
      max-height: 70%;
    }
  }
}

.show-more-button {
  position: relative;
  left: 50%;
  top: 25px;
  transform: translateX(-50%);
}

.none {
  display: none !important;
}

.drop-in {
  &:before {
    content: '';
  }
}

ion-button::part(native) {
  background: none;
  box-shadow: none;

  &:after {
    background: none !important;
  }
}

.profile {
  display: flex;
  align-items: center;

  .avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--ion-tab-bar-background, #1f1f1f);
    border-radius: 50%;
    border: 2px solid #00a0a1;
    margin-right: 0.5rem;
    height: 48px;
    width: 48px;
  }

  .profile-name {
    background: #1f1f1fbd;
    padding: 5px 10px;
    border-radius: 6px;
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.file-label {
  position: absolute;
  width: 100%;
  top: calc(100% - 24px - 4px);
  left: 0;
  pointer-events: none;
  background: linear-gradient(180deg, #1f1f1f00 0%, #00000082 100%);
  padding: 5px;

  svg {
    width: 18px;
    height: 18px;
  }
}

.audio-view {
  border: 2px solid var(--ion-tab-bar-background, #1f1f1f) !important;
  position: relative;

  svg {
    height: 100%;
    width: 100%;
  }

  .text-overflow {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 1px 3px;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
  }
}

.file-container {
  aspect-ratio: 9 / 16;
  -o-object-fit: cover;
  object-fit: cover;
  position: relative;
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
  border-radius: 3px;
  cursor: pointer;
  width: calc(33% - #{$gallery-gap});
  border: 1px solid #0d0d0d;

  ion-button {
    bottom: 0;
    top: 0;
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    opacity: 0;
    margin: 0;
  }

  @media (min-width: 769px) {
    width: calc(10% - #{$gallery-gap});
  }

  @media (max-width: 768px) and (min-width: 576px) {
    width: calc(20% - #{$gallery-gap});
  }
}
</style>
