import { emitter } from '@/bus';
export default {
  methods: {
    getCurrentDateTimeString(): string {
      const offset = new Date().getTimezoneOffset();
      return new Date(Date.now() - offset * 60000).toISOString().slice(0, 19);
    },
    microInteraction(event: string): void {
      emitter.emit('microInteraction', event);
    },
  },
};
