export default function formatBytes(bytes: number, decimals = 1): string {
  if (bytes === 0) {
    return '0 B';
  }

  const k = 1024;
  const dm = Math.max(0, decimals);
  const sizes = ['B', 'KB', 'MB', 'GB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  const num = bytes / Math.pow(k, i);

  return num.toFixed(dm) + ' ' + sizes[i];
}
