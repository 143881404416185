import { MatchEventCard } from '@/models/match-event-card';

export function formatGameMinute(card: MatchEventCard): `${number}+${number}'` | `${number}'` | '' {
  const firstElement = card.elements[0];

  if (card.event === 'MatchKickoff') {
    return "0'";
  }

  if (card.event === 'MatchHalftimeKickoff') {
    return "45'";
  }

  switch (firstElement.$type) {
    case 'Video':
    case 'Audio':
    case 'Image':
      return '';
    case 'MatchKickoff':
      return "0'";
  }

  if (hasGameMinuteInfo(firstElement)) {
    if (firstElement.minute === undefined) {
      console.warn(`Retrieving the game minute for element of type ${firstElement.$type} is not implemented.`, card);
      return '';
    }

    if (firstElement.minuteAdditional) {
      return `${firstElement.minute}+${firstElement.minuteAdditional}'`;
    }
    return `${firstElement.minute}'`;
  }

  return '';
}

function hasGameMinuteInfo<T extends object>(
  element?: T,
): element is T & { minute?: number; minuteAdditional?: number } {
  return !!element && ('minute' in element || 'minuteAdditional' in element);
}
