<template>
  <div
    class="container p-4"
    @dragover.prevent="draggingCSSclass = true"
    @dragleave="draggingCSSclass = false"
    @drop="
      onDrop($event);
      draggingCSSclass = false;
    "
  >
    <div class="relative user-profile p-4 flex flex-col items-center">
      <div class="user-profile-info">
        <div
          class="user-profile-picture"
          :class="{
            dragging: draggingCSSclass,
            'user-profile-picture__active': uploadActive,
          }"
        >
          <img
            v-if="myImage.indexOf('undefined') === -1 && showProfilImage"
            alt=""
            class="hidden"
            :src="myImage"
            @load="addLoadedClass"
          />
          <img v-else src="../../Assets/images/contentcreator_placeholder.png" alt="" />
          <div class="drag-overlay">
            <ion-icon :icon="cameraOutline" />
          </div>
          <label class="user-profile-edit-picture" for="edit-picutre">
            <ion-icon :icon="cameraOutline" color="primary" />
          </label>
          <input
            class="hidden"
            type="file"
            id="edit-picutre"
            @change="onFileChange"
            accept=".webp, .tga, .bmp, .png, .jpeg, .tiff, .gif, .pbm"
          />
        </div>
        <div v-if="myImage.indexOf('undefined') > -1" class="w-1/2 m-auto text-center text-primary">
          Lade ein Bild von dir hoch, damit deine Beiträge ein Gesicht bekommen!
        </div>
        <ion-accordion-group v-if="myHandleName">
          <ion-accordion value="first">
            <ion-item slot="header" color="light">
              <ion-label
                ><div class="user-profile-name">{{ myHandleName }}<ion-icon :icon="pencil" /></div
              ></ion-label>
            </ion-item>
            <div class="accordion-content" slot="content">
              <ion-item slot="content">
                <div class="w-full">
                  <input-text v-model="newHandle" :minlength="4" :maxlength="20" />
                  <div v-if="showHandleSuggest" class="mt-8 relative z-10 text-left">
                    <h2>
                      Dieser Username kann leider nicht gewählt werden, aber vielleicht gefällt dir ja einer von diesen:
                    </h2>
                    <ul>
                      <li
                        v-for="suggestedHandle in suggestHandleList"
                        @click="selectHandle(suggestedHandle)"
                        :key="suggestedHandle"
                      >
                        {{ suggestedHandle }}
                      </li>
                    </ul>
                  </div>
                  <button-component
                    :storage-process="storageProcess"
                    @click="saveHandle"
                    class="mb-4 mt-2 relative z-10"
                  >
                    Speichern
                  </button-component>
                </div>
              </ion-item>
            </div>
          </ion-accordion>
        </ion-accordion-group>
        <ion-accordion-group v-else>
          <ion-accordion value="first">
            <ion-item slot="header" color="light">
              <ion-label v-if="!myHandleName"
                >Verrate uns deinen Usernamen!
                <ion-icon
                  class="inline-block"
                  style="display: inline-block; vertical-align: middle; margin-left: 10px"
                  :icon="pencil"
              /></ion-label>
            </ion-item>
            <div class="accordion-content" slot="content">
              <ion-item slot="content">
                <div class="w-full">
                  <input-text v-model="newHandle" :minlength="4" :maxlength="20" />
                  <div v-if="showHandleSuggest" class="mt-8 relative z-10 text-left">
                    <h2>
                      Dieser Username kann leider nicht gewählt werden, aber vielleicht gefällt dir ja einer von diesen:
                    </h2>
                    <ul>
                      <li
                        class="cursor-pointer"
                        v-for="suggestedHandle in suggestHandleList"
                        @click="selectHandle(suggestedHandle)"
                        :key="suggestedHandle"
                      >
                        {{ suggestedHandle }}
                      </li>
                    </ul>
                  </div>
                  <div @click="saveHandle" class="mt-8 relative z-10">
                    <button-component :storage-process="storageProcess" class="default-button">
                      speichern
                    </button-component>
                  </div>
                </div>
              </ion-item>
            </div>
          </ion-accordion>
        </ion-accordion-group>
        <div class="user-profile-email">{{ userEmail }}</div>
      </div>
      <check-rights class="w-auto mt-4" v-if="showCheckRights" />
      <div v-else class="flex justify-center mt-4">
        <ion-button @click="showCheckRights = true"> Rechte prüfen </ion-button>
      </div>
      <div class="flex justify-center mt-4">
        <ion-button @click="doLogout" class="text-danger"> Ausloggen </ion-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, nextTick, onMounted, ref } from 'vue';
import { useAuthStore } from '@/store/auth';
import { IonAccordion, IonAccordionGroup, IonButton, IonIcon, IonItem, IonLabel } from '@ionic/vue';
import apiRequest from '@/services/apiRequest';
import InputText from '@/components/functionality/InputText.vue';
import ButtonComponent from '@/components/layouts/ButtonComponent.vue';
import helper from '@/services/helper';
import { cameraOutline, pencil } from 'ionicons/icons';
import CheckRights from '@/views/profile/CheckPermissions.vue';

const authStore = useAuthStore();
const userEmail = ref('');
const handle = ref('');
const newHandle = ref('');
const storageProcess = ref(false);
const showHandleSuggest = ref(false);
const suggestHandleList = ref({});
const draggingCSSclass = ref(false);
const uploadActive = ref(false);
const showProfilImage = ref(true);
const hasChanged = ref(false);
const file = ref<File | null>(null);
const myProfile = ref<NonNullable<ReturnType<typeof useAuthStore>['profile']>>({
  userId: '',
  handle: '',
  official: false,
  avatarImageUrl: '',
  avatarThumbnailUrl: '',
});
const showCheckRights = ref(false);

const myProfileFunction = () => {
  nextTick(() => {
    myProfile.value = useAuthStore().profile!;
    handle.value = useAuthStore().profile?.handle || '';
  });
};

const onDrop = async (e: DragEvent) => {
  e.preventDefault();
  const files = e.dataTransfer?.files;
  if (!files || files.length !== 1) {
    helper.methods.microInteraction('Bitte laden Sie nur eine Datei hoch.');
    return;
  }
  const fileToUpload: File = files[0];
  await processFile(fileToUpload);
};

const processFile = async (fileToProcess: File) => {
  const fileExtension = fileToProcess.name.split('.').pop()?.toLowerCase();
  const validExtensions = ['jpg', 'jpeg', 'png', 'gif', 'svg', 'webp', 'heic'];
  if (validExtensions.includes(fileExtension || '')) {
    file.value = fileToProcess;
    await profilImageUpload();
  } else {
    helper.methods.microInteraction(
      'Ungültiger Dateityp. Nur JPG, JPEG, PNG, GIF, SVG, WEBP und HEIC Dateien sind erlaubt.',
    );
  }
};

const addLoadedClass = (event: Event) => {
  const target = event.target as HTMLElement | null;
  if (target) {
    target.classList.remove('hidden');
  }
};

const onFileChange = async (e: Event) => {
  const inputEvent = e as InputEvent;
  const target = inputEvent.target as HTMLInputElement;

  if (target.files && target.files.length > 0) {
    const selectedFile: File = target.files[0];
    await processFile(selectedFile);
  }
};

const imageUpdateTrigger = ref(0);
const cacheBuster = computed(() => '?cache=' + new Date().getUTCMilliseconds() + '&' + imageUpdateTrigger.value);
const profileImage = computed(() => {
  const profile = useAuthStore().profile;
  if (!profile || !profile.avatarThumbnailUrl) {
    return '';
  }
  let url = profile.avatarThumbnailUrl;
  url = url.replace('avatar.source', 'avatar.thumbnail');
  if (!url.includes('?cache=')) {
    url += `?cache=${cacheBuster.value}`;
  }
  return url;
});

const profileImageChanged = computed(() => {
  const profile = myProfile.value;
  if (!profile || !profile.avatarThumbnailUrl) {
    return '';
  }
  let url = profile.avatarThumbnailUrl;
  url = url.replace('avatar.source', 'avatar.thumbnail');
  if (!url.includes('?cache=')) {
    url += `?cache=${cacheBuster.value}`;
  }
  return url;
});

const myImage = computed(() => {
  if (hasChanged.value) {
    return profileImageChanged.value;
  } else {
    return profileImage.value;
  }
});
const myHandleName = computed(() => useAuthStore().profile?.handle);
const profilImageUpload = async () => {
  if (!file.value) {
    helper.methods.microInteraction('Kein Profilbild ausgewählt');
    return;
  }
  helper.methods.microInteraction('Kurzer Augenblick, wir laden dein Bild hoch!');
  uploadActive.value = true;
  const url = 'profiles/uploadAvatar';
  const result = await apiRequest.methods.putFile(url, 'profiles', file.value);
  if (result.error) {
    console.error(result.error);
    helper.methods.microInteraction(
      'Dein Profilbild konnte nicht gespeichert werden, probiere es bitte mit einem anderen Bild!',
    );
  } else {
    helper.methods.microInteraction(
      'Du siehst gut aus! Danke, dass du mit deinem Bild Leagues persönlicher werden lässt!',
    );
    myProfile.value.avatarThumbnailUrl = '';
    showProfilImage.value = false;
    await nextTick();
    useAuthStore().profile!.avatarThumbnailUrl = '';
    myProfile.value.avatarThumbnailUrl = result.data;
    await nextTick();
    useAuthStore().profile!.avatarThumbnailUrl = result.data + cacheBuster.value;
    useAuthStore().profile!.avatarImageUrl = result.data + cacheBuster.value;
    showProfilImage.value = true;
    hasChanged.value = true;
    imageUpdateTrigger.value++;
  }
  uploadActive.value = false;
};

const suggestHandle = async () => {
  const url = 'handles/suggest?handle=' + newHandle.value + '&count=10';
  const result = await apiRequest.methods.get(url, 'profiles');
  if (result.error) {
    console.error('Error fetching player:', result.error);
  } else {
    suggestHandleList.value = result.data;
    showHandleSuggest.value = true;
  }
};

const validHandle = async () => {
  const url = 'handles/validate?handle=' + newHandle.value;
  const result = await apiRequest.methods.get(url, 'profiles');
  if (result.error) {
    // @ts-expect-error Not sure what's supposed to happen here... is the result a {data: {response: any}} ??
    if (typeof result?.data?.response?.data === 'string' && result.data.response.data.includes('Invalid')) {
      await suggestHandle();
      return false;
    }
  } else {
    console.log(result.data);
    return true;
  }
};

const saveHandle = async () => {
  storageProcess.value = true;
  newHandle.value = newHandle.value.replace(/\s+/g, '');
  showHandleSuggest.value = false;
  if (!newHandle.value) {
    helper.methods.microInteraction('Du musst auch einen Usernamen eingeben');
    return;
  }
  if (newHandle.value.length < 4) {
    helper.methods.microInteraction('Dein Username ist leider zu kurz');
    return;
  }
  if (await validHandle()) {
    const result = await apiRequest.methods.put('handles/assign?handle=' + newHandle.value, 'profiles', {});
    if (result.error) {
      if ((result.error as any).response.data['daysToWait']) {
        helper.methods.microInteraction(
          'Sorry, du kannst erst in ' +
            (result.error as any).response.data['daysToWait'] +
            ' Tagen deinen Namen wieder ändern!',
        );
        storageProcess.value = false;
        return;
      }
      helper.methods.microInteraction('Dein User Name wurde nicht geändert');
    } else {
      console.log(result.data);
      useAuthStore().profile!.handle = newHandle.value;
      helper.methods.microInteraction('Dein User Name wurde geändert');
    }
    storageProcess.value = false;
  }
};

onMounted(() => {
  userEmail.value = authStore.userEmail || '';
  myProfileFunction();
});

const doLogout = async () => await authStore.logoutUser();

const selectHandle = (selectedHandle: string) => {
  newHandle.value = selectedHandle;
  saveHandle();
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
}

.dragging-over {
  background-color: #f8f9fa;
}

.text-danger {
  color: var(--ion-color-danger) !important;
}
</style>
