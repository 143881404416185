<template>
  <div class="teamNamePattern">
    <input-text
      v-model="searchTerm"
      @update:model-value="searchVenue"
      placeholder="Suche"
      :options="[
        { key: 'name', value: 'Name' },
        { key: 'cityName', value: 'Stadt' },
      ]"
      @selectOption="onSearchOptionSelected"
      data-testid="venue-search"
    />
    <div v-if="venueSearchResults.length" class="teamNamePattern__list">
      <pattern-list v-for="venue in venueSearchResults" :key="venue.uid" @click="selectVenue(venue)">
        <div class="venue-wrapper">
          <b><span v-if="venue.name" v-html="venue.name" /></b>
          <div class="flex gap-2 text-xs">
            <span v-if="venue.cityName" v-html="venue.cityName" /> &bull;
            <span v-if="venue.address" v-html="venue.address" />
          </div>
          <div class="flex gap-2 text-xs">Oberfläche: <span v-if="venue.surface" v-html="venue.surface" /></div>
        </div>
      </pattern-list>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { nextTick, ref, watch } from 'vue';
import InputText from '@/components/functionality/InputText.vue';
import apiRequest from '@/services/apiRequest';
import PatternList from '@/components/helper/pattern/PatternList.vue';
import type { Venue } from '@/models/Venue';
import { Team } from '@/models/team';

const emit = defineEmits<{
  (e: 'searchInput', value: string): true;
}>();

const venueId = defineModel<string>();
const searchTerm = ref(venueId.value || '');

const venueSearchResults = ref([] as Venue[]);

const searchOption = ref<'name' | 'cityName'>('name');

const searchVenue = async () => {
  if (searchTerm.value.length < 2) {
    venueSearchResults.value = [];
  } else {
    const result = await apiRequest.methods.get(
      `api/data/venues?page=0&size=10&${searchOption.value}=${searchTerm.value}`,
      'cloud',
    );
    if (result.error) {
      emit('searchInput', searchTerm.value);
    } else {
      if (Object.keys(result.data).length) {
        emit('searchInput', '');
      } else {
        emit('searchInput', searchTerm.value);
      }
      venueSearchResults.value = result.data;
    }
  }
};

watch(
  venueId,
  async (newVenueId) => {
    if (!newVenueId) {
      return;
    }

    const response = await apiRequest.methods.get<Team>(`api/data/venues/${newVenueId}`, 'cloud');
    if (response.data) {
      searchTerm.value = response.data.name;
    }
  },
  { immediate: true },
);

const selectVenue = (venue: Venue) => {
  venueId.value = venue.uid;
  searchTerm.value = venue.name;
  venueSearchResults.value = [];
};

const onSearchOptionSelected = (e: 'name' | 'cityName') => {
  searchOption.value = e;
  nextTick(() => {
    if (searchTerm.value.length >= 2) {
      searchVenue();
    }
  });
};
</script>

<style lang="scss" scoped>
input {
  padding: 2px 4px;
  border-radius: 5px;
  text-align: left;
  width: 100%;
}
.teamNamePattern {
  position: relative;
  text-align: center;
  background: transparent !important;

  .inputText {
    background: transparent !important;
  }

  .teamNamePattern__badge {
    display: inline-block;
  }
}
.venue-wrapper {
  display: flex;
  flex-direction: column;
}
</style>
