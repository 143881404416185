<template>
  <div class="teamNamePattern">
    <club-badge v-if="showBadge" :image="badgeUrl" class="teamNamePattern__badge"></club-badge>
    <div class="search-input">
      <input-text
        v-model="searchTerm"
        @update:model-value="searchTeams"
        placeholder="Suche"
        data-testid="team-search"
      />
      <ion-icon :icon="searchOutline" color="dark" />
    </div>
    <div v-if="teams.length" class="teamNamePattern__list">
      <pattern-list v-for="team in teams" :key="team.uid" @click="selectValue(team)">
        <img v-if="team.badgeUrl" :src="team.badgeUrl" alt="" />
        <h2 v-if="team && team.fullName">{{ team.fullName }}</h2>
      </pattern-list>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import ClubBadge from '@/components/ClubBadge.vue';
import InputText from '@/components/functionality/InputText.vue';
import apiRequest from '@/services/apiRequest';
import PatternList from '@/components/helper/pattern/PatternList.vue';
import { IonIcon } from '@ionic/vue';
import { searchOutline } from 'ionicons/icons';
import { Team } from '@/models/team';

const props = withDefaults(
  defineProps<{
    initialId?: string;
    showBadge?: boolean;
  }>(),
  { showBadge: true },
);

const model = defineModel<Team | undefined | null>();
const searchTerm = ref(model.value?.name || '');
const teams = ref([] as Team[]);
const badgeUrl = ref('');

const searchTeams = async () => {
  if (searchTerm.value.length >= 2) {
    const requestURL = `api/data/teams/search?namepattern=${searchTerm.value}`;
    const result = await apiRequest.methods.get<Team[]>(requestURL, 'cloud');
    if (result.data) {
      teams.value = result.data;
    }
  } else {
    teams.value = [];
  }
};

const selectValue = (team: Team) => {
  model.value = team;
  searchTerm.value = team.name;
  teams.value = [];
  badgeUrl.value = team.badgeUrl;
};

// Watch for changes in setID prop and send request
watch(
  () => props.initialId,
  async (teamId) => {
    if (!teamId) {
      return;
    }
    console.log({ teamId });

    const requestURL = `api/data/teams/${teamId}`;

    const result = await apiRequest.methods.get<Team>(requestURL, 'cloud');
    if (result.data) {
      badgeUrl.value = result.data.badgeUrl;
      searchTerm.value = result.data.name;
      selectValue(result.data);
    }
  },
  { immediate: true },
);
</script>
