<script lang="ts" setup>
import { computed, onBeforeUnmount, ref } from 'vue';
import { useAuthStore } from '@/store/auth';
import { IonContent, IonRefresher, IonRefresherContent } from '@ionic/vue';
import { emitter } from '@/bus';
import { FileWithKind, ThumbnailURL } from '@/services/filePicker/models';
import publishService from '@/services/publish';
import CloudFileGallery from '@/components/CloudFileGallery.vue';
import FilePicker from '@/components/FilePicker.vue';
import PickedFileThumbnailContainer from '@/components/PickedFileThumbnailContainer.vue';

// File picker
const fileToUpload = ref<FileWithKind>();
const thumbnailUrl = ref<ThumbnailURL | undefined>(undefined);

const isOver = ref(false);

const inboxId = computed(() => useAuthStore().userSettings.selectedTimeline?.uid);

const forceRefresh = async () => {
  console.warn('Not implemented. TODO: Instruct child component to refresh!');
};

async function uploadFile() {
  isOver.value = false;

  const file = fileToUpload.value;

  if (!file || !inboxId.value) {
    return;
  }

  await publishService.uploadCloudFile({
    file,
    inboxId: inboxId.value,
    thumbnail: thumbnailUrl.value,
  });

  await forceRefresh();
}

onBeforeUnmount(() => emitter.all.delete('refreshPage'));
</script>

<template>
  <div class="container h-full p-4 cloud-container">
    <ion-content class="ion-padding remove-scrollbar-desktop">
      <picked-file-thumbnail-container v-if="!!thumbnailUrl" :thumbnail-url="thumbnailUrl" />
      <file-picker
        class="mb-6"
        :can-upload-from-cloud="false"
        @fileSelected="
          (file, thumb) => {
            fileToUpload = file;
            thumbnailUrl = thumb;
            uploadFile();
          }
        "
      />
      <ion-refresher slot="fixed" @ionRefresh="forceRefresh()">
        <ion-refresher-content />
      </ion-refresher>
      <cloud-file-gallery />
    </ion-content>
  </div>
</template>

<style lang="scss" scoped>
$gallery-gap: 2px;

.cloud-container {
  ion-content {
    --overflow: unset;
    --ion-background-color: black;
  }
}

.drop-in {
  &:before {
    content: '';
  }
}

.bottom-drawer {
  ion-button::part(native) {
    background: none;
    box-shadow: none;
    margin-top: 3px;

    &:after {
      background: none !important;
    }
  }

  .profile {
    position: absolute;
    top: 1rem;
    left: 1rem;
    display: flex;
    align-items: center;

    .avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--ion-tab-bar-background, #1f1f1f);
      border-radius: 50%;
      border: 2px solid #00a0a1;
      margin-right: 0.5rem;
      height: 48px;
      width: 48px;
    }

    .profile-name {
      background: #1f1f1fbd;
      padding: 5px 10px;
      border-radius: 6px;
      max-width: 200px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.cloud-container,
.collaboration-container {
  .file-video {
    .file-label {
      position: absolute;
      width: 100%;
      top: calc(100% - 24px - 4px);
      left: 0;
      pointer-events: none;
      background: linear-gradient(180deg, #1f1f1f00 0%, #00000082 100%);
      padding: 5px;

      svg {
        width: 18px;
        height: 18px;
      }
    }
  }

  .audio-view {
    border: 2px solid var(--ion-tab-bar-background, #1f1f1f) !important;
    position: relative;

    svg {
      height: 100%;
      width: 100%;
    }

    .text-overflow {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 1px 3px;
      text-overflow: ellipsis;
      width: 100%;
      overflow: hidden;
    }
  }

  .file-container {
    aspect-ratio: 9 / 16;
    -o-object-fit: cover;
    object-fit: cover;
    position: relative;
    overflow: hidden;
    display: inline-block;
    vertical-align: top;
    border-radius: 3px;
    cursor: pointer;
    width: calc(33% - #{$gallery-gap});
    border: 1px solid #0d0d0d;

    ion-button {
      bottom: 0;
      top: 0;
      position: absolute;
      left: 0;
      right: 0;
      height: 100%;
      opacity: 0;
      margin: 0;
    }

    @media (min-width: 769px) {
      width: calc(10% - #{$gallery-gap});
    }

    @media (max-width: 768px) and (min-width: 576px) {
      width: calc(20% - #{$gallery-gap});
    }
  }
}
</style>
