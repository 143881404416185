/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FilePicker } from '@capawesome/capacitor-file-picker';
import { useAppStore } from '@/store/app';
import { Capacitor } from '@capacitor/core';
import { VideoEditor } from '@awesome-cordova-plugins/video-editor';
import { FileKind, PickedFile, ThumbnailURL } from './models';
import { Filesystem, ReadFileResult } from '@capacitor/filesystem';
import { getFileKindFromMimeType, getMimeTypesFromFileKind } from '@/services/filePicker/mime-types';
import { b64toBlob } from '@/utils/base64-utils';
import { v4 as uuidv4 } from 'uuid';
import { isPlatform } from '@ionic/vue';

export default {
  pickSingleFile: async (allowedFileKind?: FileKind) => {
    if (useAppStore().isNative) {
      const result = await FilePicker.pickMedia({
        readData: false,
        limit: 1,
      }).catch(console.error);
      if (result?.files[0]) {
        return result.files[0];
      }
    } else {
      const result = await FilePicker.pickFiles({
        readData: false,
        limit: 1,
        types: allowedFileKind && (getMimeTypesFromFileKind(allowedFileKind) as unknown as string[]),
      }).catch(console.error);
      return result?.files[0];
    }
  },

  async generateThumbnailURL(file: PickedFile): Promise<ThumbnailURL | null> {
    const blob = await generateThumbnailAsBlob(file);
    if (!blob) return null;

    return URL.createObjectURL(blob);
  },
};

const VIDEO_THUMBAIL_SCREENSHOT_AT_SECOND = 2;

async function generateThumbnailAsBlob(file: PickedFile): Promise<Blob | null> {
  const fileKind = getFileKindFromMimeType(file.mimeType);

  switch (true) {
    case !!file.blob:
      return await generateThumbnailFromBlob(file.blob!, fileKind);
    case !!file.path:
      if (!Capacitor.isNativePlatform()) {
        // Sanity check
        throw Error('A file path is expected for picked files on native devices');
      }
      return await generateThumbnailNative(file.path!, fileKind);
    default:
      console.error('The chosen file does not contain a path or a blob. Cannot generate thumbnail');
      return null;
  }
}

async function generateThumbnailNative(filePath: string, fileKind: ReturnType<typeof getFileKindFromMimeType>) {
  if (isPlatform('ios')) {
    const convertedPath = Capacitor.convertFileSrc(filePath);
    const decodedFileName = decodeURIComponent(convertedPath);
    const containsSpace = decodedFileName.includes(' ');
    if (containsSpace) {
      return null; // Apparently, on iOS the plugin isn't happy with spaces in file names. On Android, it works just fine
      // TODO: Test again
    }
  }

  switch (fileKind) {
    case 'image':
      return b64toBlob(await base64ImageFromFilePath(filePath));
    case 'video':
      return b64toBlob(await createBase64ThumbnailNative(filePath));
    case 'audio':
      return null; // TODO: Pass some static audio thumbnail
    default:
      return null;
  }
}

async function generateThumbnailFromBlob(blob: Blob, fileKind: ReturnType<typeof getFileKindFromMimeType>) {
  switch (fileKind) {
    case 'video':
      return await generateVideoThumbnail(blob);
    case 'image':
      return blob;
    case 'audio':
      return null; // TODO: Pass some static audio thumbnail
    default:
      return null;
  }
}

async function createBase64ThumbnailNative(filePath: string) {
  const thumbPath = await VideoEditor.createThumbnail({
    fileUri: filePath,
    outputFileName: `thumb-${uuidv4()}`,
    atTime: VIDEO_THUMBAIL_SCREENSHOT_AT_SECOND,
    quality: 100,
  });

  const result = await base64ImageFromFilePath(thumbPath);

  Filesystem.deleteFile({
    path: thumbPath,
  }).catch((err) => console.error(`Unable to clean up thumbnail file at ${thumbPath}`, err));

  return result;
}

/**
 * Only native
 */
const base64ImageFromFilePath = async (contentPath: string): Promise<string | null> => {
  const filePath = contentPath.includes('://') ? contentPath : 'file://' + contentPath;

  const file: ReadFileResult = await Filesystem.readFile({
    path: filePath,
    directory: undefined,
  });

  try {
    return file.data as string | null; // Blob is impossible because this is only ran on native
  } catch (error) {
    console.error(`Fehler beim Lesen der Datei "${filePath}":`, error);
    return null;
  }
};

// noinspection JSUnusedLocalSymbols
function generateVideoThumbnail(filePath: string): Promise<Blob | null>;
function generateVideoThumbnail(blob: Blob): Promise<Blob | null>;
function generateVideoThumbnail(filePathOrBlob: Blob | string): Promise<Blob | null> {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');
    video.controls = false;
    video.preload = 'metadata';
    video.muted = true;
    video.style.display = 'none';
    video.autoplay = true;
    video.playsInline = true;
    video.currentTime = VIDEO_THUMBAIL_SCREENSHOT_AT_SECOND;

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const objectUrl = filePathOrBlob instanceof Blob ? URL.createObjectURL(filePathOrBlob) : filePathOrBlob;

    video.addEventListener('canplay', () => {
      if (!ctx) {
        reject(new Error('2D-Kontext des Canvas ist nicht verfügbar.'));
        return;
      }

      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

      canvas.toBlob((thumbnailBlob) => {
        resolve(thumbnailBlob);
        video.pause();
        video.src = '';
        video.load();
        filePathOrBlob instanceof Blob && URL.revokeObjectURL(objectUrl);
        video.remove();
      }, 'image/jpeg');
    });

    video.addEventListener('error', () => {
      reject(new Error('Thumbnail konnte nicht generiert werden.'));
      URL.revokeObjectURL(objectUrl);
      video.remove();
    });

    video.src = objectUrl;
  });
}
