import { computed, type Ref, ref, watch } from 'vue';
import type { Player, PlayerId } from '@/models/player';
import { MatchEventCard } from '@/models/match-event-card';
import { NewsPostCard } from '@/models/news-post-card';
import { useCardFirstElement } from '@/models/useCardFirstElement';

export function usePlayerChange(card: Ref<MatchEventCard | NewsPostCard | undefined>) {
  const { firstElement: element } = useCardFirstElement(card);

  const changedPlayer = ref<Player>();

  function asElementWithUpdatedPlayer<T>(element: T, player: Player) {
    return {
      ...element,
      playerId: player.uid,
      playerNumber: player.jerseyNumber?.toString(),
      playerPhotoUrl: player.profilePictureUrl,
      playerText: player.fullName,
    };
  }

  const playerId = computed<PlayerId | undefined>(() =>
    element.value && 'playerId' in element.value ? element.value.playerId : undefined,
  );
  const teamId = computed<string | undefined>(() =>
    element.value && 'teamId' in element.value ? element.value.teamId : undefined,
  );
  const matchFixtureId = computed<string | undefined>(() => card.value?.properties?.classKey?.entityId);

  watch(
    card,
    (newValue, oldValue) => {
      if (newValue !== oldValue) {
        changedPlayer.value = undefined;
      }
    },
    { immediate: true },
  );

  return { changedPlayer, asElementWithUpdatedPlayer, playerId, teamId, matchFixtureId };
}
