<script lang="ts" setup>
import { checkmarkOutline, cloudDownloadOutline, trashOutline } from 'ionicons/icons';
import { alertController, IonButton, IonContent, IonIcon, IonSpinner, modalController } from '@ionic/vue';
import { CloudFile } from '@/views/cloud-page/CloudFile';
import { useAuthStore } from '@/store/auth';
import { useAppStore } from '@/store/app';
import axios from 'axios';
import { computed, ref } from 'vue';

defineProps<{ file: CloudFile; selectMode: boolean }>();

const inboxId = computed(() => useAuthStore().userSettings.selectedTimeline?.uid);

const emit = defineEmits<{ (e: 'reset'): unknown; (e: 'selected', cloudFile: CloudFile): unknown }>();

function formatTimestamp(timestamp: string): string {
  const date: Date = new Date(timestamp);
  const dateInMilliseconds: number = date.getTime();

  const now: number = Date.now();
  const diff: number = Math.floor((now - dateInMilliseconds) / 1000);

  const offset = date.getTimezoneOffset() * 60;
  const adjustedDiff = diff + offset;

  if (adjustedDiff < 60) {
    return adjustedDiff === 1 ? 'einer Sekunde' : adjustedDiff + ' Sekunden';
  } else if (adjustedDiff < 60 * 60) {
    const minutes = Math.floor(adjustedDiff / 60);
    return minutes === 1 ? 'einer Minute' : minutes + ' Minuten';
  } else if (adjustedDiff < 60 * 60 * 24) {
    const hours = Math.floor(adjustedDiff / (60 * 60));
    return hours === 1 ? 'einer Stunde' : hours + ' Stunden';
  } else if (adjustedDiff < 60 * 60 * 24 * 30) {
    const days = Math.floor(adjustedDiff / (60 * 60 * 24));
    return days === 1 ? 'einem Tag' : days + ' Tagen';
  } else if (adjustedDiff < 60 * 60 * 24 * 30 * 12) {
    const months = Math.floor(adjustedDiff / (60 * 60 * 24 * 30));
    return months === 1 ? 'einem Monat' : months + ' Monaten';
  } else {
    const years = Math.floor(adjustedDiff / (60 * 60 * 24 * 30 * 12));
    return years === 1 ? 'einem Jahr' : years + ' Jahren';
  }
}

const deletionInProgress = ref(false);

async function requestDelete(fileName: string) {
  const alert = await alertController.create({
    header: 'Achtung!',
    cssClass: 'custom-alert',
    subHeader: 'Bist du dir sicher, dass du diese Datei aus der Cloud löschen willst?',
    buttons: [
      {
        text: 'Ja',
        role: 'confirm',
        handler: () => {
          deleteFile(fileName);
        },
      },
      {
        text: 'abbrechen',
        role: 'cancel',
      },
    ],
  });

  await alert.present();
  return;
}

function deleteFile(fileName: string) {
  const appStore = useAppStore();
  deletionInProgress.value = true;
  const requestURL =
    'https://cloud-' +
    appStore.environment +
    '.leagues.football/api/inbox?inbox=' +
    inboxId.value +
    '&filename=' +
    fileName;
  const headers = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json; charset=utf-8',
    Authorization: 'Bearer ' + useAuthStore().accessToken,
  };
  axios
    .delete(requestURL, { headers })
    .then(() => {
      modalController.dismiss();
      emit('reset');
      deletionInProgress.value = false;
    })
    .catch(() => {
      deletionInProgress.value = false;
      const bannerObject = {
        success: false,
        message: 'Das Löschen der Datei ist fehlgeschlagen',
      };
      appStore.addBanner(bannerObject);
    });
}
</script>

<template>
  <ion-content class="ion-padding h-full">
    <div v-if="file.contentType.includes('image')" class="modal-file h-full">
      <div>
        <img :src="file.url" alt="" />
        <div class="file-details">
          <ul>
            <li class="text-overflow">{{ file.fileName }}</li>
            <li>
              <span>{{ file.creator }}</span>
              <div>⦁ vor {{ formatTimestamp(file.timestamp) }}</div>
            </li>
          </ul>
        </div>
        <div class="flex">
          <ion-button class="default-button" color="success" @click="emit('selected', file)">
            <span>Auswählen</span>
          </ion-button>
        </div>
      </div>
    </div>
    <!--.mp3-->
    <div v-else class="modal-file">
      <!-- #t=0.1 and preload="metadata", damit auf mobil ein Thumbnail angezeigt wird -->
      <div>
        <video :src="file.url" class="video-preview" loop autoplay muted playsinline controls />
        <div class="file-details">
          <ul>
            <li class="text-overflow">{{ file.fileName }}</li>
            <li>
              <span>{{ file.creator }}</span>
              <div>⦁ vor {{ formatTimestamp(file.timestamp) }}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <template v-if="selectMode">
      <div>
        <ion-button class="default-button" color="success" @click="emit('selected', file)">
          <ion-icon :icon="checkmarkOutline"></ion-icon>
        </ion-button>
      </div>
    </template>
    <template v-else>
      <ion-button class="trash-icon" color="danger" @click="requestDelete(file.fileName)">
        <ion-icon v-if="!deletionInProgress" :icon="trashOutline" />
        <ion-spinner v-else name="crescent" />
      </ion-button>
      <a class="download-icon" target="_blank" :href="file.url"
        ><ion-icon color="primary" :icon="cloudDownloadOutline"></ion-icon
      ></a>
    </template>
  </ion-content>
</template>

<style lang="scss" scoped>
.modal-file {
  position: relative;

  video,
  img,
  svg {
    max-height: 270px;
    max-width: 100%;
    margin: auto;
  }
}

.select-icon {
  background: linear-gradient(180deg, #00d4d6 0%, #00a0a1 100%) !important;

  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  margin: 0;
}

.trash-icon {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  background: var(--ion-color-danger, #eb445a);
  height: 48px;
  width: 48px;
  border-radius: 50%;
  margin: 0;
}

.download-icon {
  position: absolute;
  top: 5.5rem;
  right: 1.5rem;
  background: var(--ion-tab-bar-background, #1f1f1f);
  height: 48px;
  width: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  ion-icon {
    margin: 0;
  }
}

.trash-icon,
.download-icon {
  box-shadow: #0000003d 0 3px 8px;
}

.cloud-icon {
  color: black;
  height: 100px;
  width: 100px;
}

.file-details {
  background: #181818;
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 6px;
  line-height: 1.7;
  position: relative;

  ul {
    li {
      display: flex;
      flex-flow: wrap;

      span {
        margin-right: 5px;
      }

      div {
        white-space: nowrap;
      }
    }

    @media (max-width: 768px) {
      .text-overflow {
        overflow: scroll;
        white-space: nowrap;
        padding-right: 30px;
      }
    }
  }

  .fade-out {
    position: absolute;
    height: 30px;
    width: 30px;
    right: 1rem;
    top: 1rem;
    background: linear-gradient(90deg, #1f1f1f00 0%, #181818 81%);
  }
}

.default-button {
  margin: 0 auto;
  display: block;
  width: 120px;
  margin-top: 1rem;
}
</style>
