<script setup lang="ts">
import { computed, ComputedRef, onMounted, ref, watch, watchEffect } from 'vue';
import { useAuthStore } from '@/store/auth';
import { useAppStore } from '@/store/app';
import { MatchEventCard } from '@/models/match-event-card';
import GhostWriter from '@/components/GhostWriter.vue';
import SuperSubMatchReporter from '@/components/superSub/SuperSubMatchReporter.vue';
import { MatchElement } from '@/models/match-elements';
import { MediaElement } from '@/models/media-element';
import { FileWithKind, ThumbnailURL } from '@/services/filePicker/models';
import publish, { isCloudFile } from '@/services/publish';
import { CloudFile } from '@/views/cloud-page/CloudFile';
import FilePickerFormContainer from '@/views/feed/FilePickerFormContainer.vue';
import { useTimelineStore } from '@/store/timeline';
import MatchingEventFoundModal from '@/views/feed/card-forms/MatchingEventFoundModal.vue';
import { DateTime } from 'luxon';

const props = defineProps<{
  cardReference?: MatchEventCard;
}>();

const firstElement = computed(() => props.cardReference?.elements[0]) as ComputedRef<
  MatchElement | MediaElement | undefined
>;

const headlineText = ref<string>('');

const showMergeWithExistingEventModal = ref<MatchEventCard | false>(false);

const latestMatchEventAtMountTime = ref<DateTime | undefined>();
const timelineStore = useTimelineStore();
onMounted(() => {
  const latestEvent = timelineStore.getMostRecentMatchEvent();
  latestMatchEventAtMountTime.value = latestEvent ? DateTime.fromISO(latestEvent.moment) : undefined;
});

const welcome = computed(() => {
  let greeting = 'Hey';
  const handle = useAuthStore().profile?.handle;
  if (handle) {
    greeting = handle;
  }
  let salutation = `${greeting}, `;
  if (eventMessage.value !== '') {
    salutation += `hast du ein Video ${eventMessage.value}?`;
  } else {
    salutation += ' teile deinen Moment mit uns!';
  }
  return salutation;
});

const eventMessage = computed(() => {
  switch (props.cardReference?.event) {
    case 'MatchPenaltyKickAwarded':
      return 'zu diesem vergebenen Elfmeter';
    case 'MatchSubstitution':
      return 'zu diesem Spielerwechsel';
    case 'MatchRedCard':
      return 'über diese rote Karte';
    case 'MatchYellowRedCard':
      return 'zu dieser Gelb-Roten Karte';
    case 'MatchYellowCard':
      return 'über diese gelbe Karte';
    case 'MatchKickoff':
      return 'zum Beginn des Spiels';
    case 'MatchHalftime':
      return 'zum Halbzeitpfiff';
    case 'MatchHalftimeKickoff':
      return 'zum Wiederanpfiff';
    case 'MatchGoal':
      return 'über dieses Tor';
    case 'MatchEnd':
      return 'zum Ende des Spiels';
    default:
      return '';
  }
});

watch(
  () => useAppStore().bottomDrawer,
  (newVal) => {
    if (!newVal) {
      resetForm();
    }
  },
);

const resetForm = () => {
  useAppStore().bottomDrawer = false;
  submittedData.value = undefined;
  headlineText.value = '';
};

type SubmitData = { file: FileWithKind | CloudFile; thumbnailUrl: ThumbnailURL; silentPost: boolean };
const submittedData = ref<SubmitData>();

const onSubmit = (file: FileWithKind | CloudFile, thumbnailUrl: ThumbnailURL, silentPost: boolean) => {
  submittedData.value = { file, thumbnailUrl, silentPost };
};

/**
 * Returns the most recent match event if it has been posted since the media posting form was opened
 */
const findPossibleMergeTarget = () => {
  const mergeTarget = timelineStore.getMostRecentMatchEvent({
    eventTypesFilter: [
      'MatchCard',
      'MatchCardAdjusted',
      'MatchCardUpgraded',
      'MatchEnd',
      'MatchGoal',
      'MatchGoalCancelled',
      'MatchGoalConfirmed',
      'MatchGoalDenied',
      'MatchGoalDisallowed',
      'MatchHalftime',
      'MatchHalftimeKickoff',
      'MatchKickoff',
      'MatchMissedPenalty',
      'MatchPenaltyAwarded',
      'MatchPenaltyCancelled',
      'MatchPenaltyConfirmed',
      'MatchPenaltyKickAwarded',
      'MatchPenaltyKickMissed',
      'MatchRedCard',
      'MatchRedCardCancelled',
      'MatchSubstitution',
      'MatchVarIntervention',
      'MatchVarInterventionGoal',
      'MatchVarInterventionPenaltyKick',
      'MatchWelcome',
      'MatchYellowCard',
      'MatchYellowCardCancelled',
      'MatchYellowRedCard',
    ],
  });
  if (
    mergeTarget &&
    (!latestMatchEventAtMountTime.value || DateTime.fromISO(mergeTarget.moment) > latestMatchEventAtMountTime.value)
  ) {
    return mergeTarget;
  }
  return undefined;
};

watchEffect(() => {
  const data = submittedData.value;
  if (!data) {
    return;
  }

  const mergeTarget = findPossibleMergeTarget();
  if (mergeTarget) {
    showMergeWithExistingEventModal.value = mergeTarget;
    return;
  }

  post(data, props.cardReference).then();
});

const post = async ({ file, thumbnailUrl, silentPost }: SubmitData, cardReference?: MatchEventCard) => {
  const headline = headlineText.value;

  resetForm();

  if (isCloudFile(file)) {
    await submitCloudFile(file, headline, thumbnailUrl, silentPost, cardReference);
  } else {
    await uploadAndSubmit(file, headline, thumbnailUrl, silentPost, cardReference);
  }
};

const submitCloudFile = async (
  cloudFile: CloudFile,
  headline: string,
  thumbnailUrl: ThumbnailURL,
  silentPost: boolean,
  cardReference?: MatchEventCard,
) => {
  if (cardReference) {
    await publish.attachCloudFileToCard({
      cloudFile,
      cardID: cardReference.id,
      headline: headline,
      thumbnail: thumbnailUrl,
    });
  } else {
    await publish.createCardWithCloudFile({ cloudFile, headline, thumbnail: thumbnailUrl, silentPost });
  }
};

const uploadAndSubmit = async (
  file: FileWithKind,
  headline: string,
  thumbnailUrl: ThumbnailURL,
  silentPost: boolean,
  cardReference?: MatchEventCard,
) => {
  if (cardReference) {
    await publish.attachMediaToCard({
      file,
      cardID: cardReference.id,
      headline: headline,
      thumbnail: thumbnailUrl,
    });
  } else {
    await publish.createCardWithMedia({ file, headline, thumbnail: thumbnailUrl, silentPost });
  }
};

const useSuperSubMessage = ref(false);
const activateSuperSubMatchReporter = (e: boolean) => {
  useSuperSubMessage.value = e;
};

const chosenPhrase = (phrase: string) => {
  headlineText.value = phrase;
};
</script>

<!--suppress HtmlUnknownTag -->
<template>
  <file-picker-form-container
    :welcome-text="welcome"
    allowed-file-kind="all"
    :view-title="(noFileSelected) => (noFileSelected ? 'Teile ein Video oder Foto' : 'Erzähl uns, was passiert ist')"
    @submit="onSubmit"
  >
    <template #form>
      <template v-if="!showMergeWithExistingEventModal">
        <ghost-writer
          v-if="useAppStore().bottomDrawer"
          :cardReference="props.cardReference"
          @change="(newValue) => (headlineText = newValue)"
        />
        <super-sub-match-reporter
          @activateSuperSubMatchReporter="activateSuperSubMatchReporter"
          @chosenPhrase="chosenPhrase"
          :eventEntityId="cardReference?.properties?.eventEntityId"
          :guestId="firstElement && 'guestId' in firstElement ? firstElement.guestId : undefined"
          :homeId="firstElement && 'homeId' in firstElement ? firstElement.homeId : undefined"
        >
        </super-sub-match-reporter>
      </template>
      <matching-event-found-modal
        v-else-if="submittedData"
        :target-card="showMergeWithExistingEventModal"
        @select="post(submittedData, $event)"
        @decline="post(submittedData, cardReference)"
      />
    </template>
  </file-picker-form-container>
</template>

<style lang="scss" scoped>
.active.feed-shortcut {
  max-height: 300px;
  margin-bottom: 0;
  padding: 1rem;
}

.input-wrapper {
  position: relative;
  margin-bottom: 2rem;

  input,
  textarea {
    position: relative;
    z-index: 1;
    background: #35393f;
    width: 100%;
    border-radius: 4px;
    padding: 5px;
  }
}

.tutorial {
  background: #ffffff14;
  border-radius: 0 9px 9px 9px;
  display: inline-block;
  padding: 5px 10px;
  font-weight: 600;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
}

.scroll-snap-container {
  min-width: 100%;
  scroll-snap-align: start;

  .scroll-to {
    text-align: center;
    color: #00d4d6 !important;
    font-size: 1.2rem;
    margin-top: 1rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    gap: 5px;
    align-items: center;
    font-weight: 600;

    ion-icon {
      margin: 0;
      color: inherit !important;
    }
  }
}

h1 {
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  width: 100%;
}

.tags {
  display: flex;
  gap: 0.5rem;
  overflow: scroll;
  flex-wrap: nowrap;

  &::-webkit-scrollbar {
    display: none;
  }

  .tag {
    border: 1px solid #5b5a5a;
    padding: 5px 10px;
    border-radius: 50px;
    background: #35393f;
    color: gray;
    font-weight: 600;
    line-height: 1;
    cursor: pointer;

    &:hover {
      border-color: #fff;
    }
  }

  .active {
    background: #68707d !important;
    color: #fff !important;
  }
}

.feed-overlay-close {
  max-height: 36px;
  max-width: 36px;
  min-width: 36px;
  background: #35393f;
  border-radius: 50%;
  padding: 6px;
  display: flex;
  justify-content: center;
}

.form-container {
  padding: 5px;
  max-width: 500px;
}

.microphone {
  cursor: pointer;
}

@keyframes shimmer {
  0% {
    left: -200%;
    top: 0;
  }

  100% {
    left: 200%;
    top: 0;
  }
}
</style>
