<script lang="ts" setup>
import { ref } from 'vue';
import { close } from 'ionicons/icons';
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonModal, IonTitle, IonToolbar } from '@ionic/vue';
import { type MatchEventCard } from '@/models/match-event-card';
import MatchEventPictogram from '@/views/feed/feed-entries/MatchEventPictogram.vue';

const isOpen = ref(true);

defineProps<{ targetCard: MatchEventCard }>();

const emit = defineEmits<{
  (e: 'select', card: MatchEventCard): void;
  (e: 'decline'): void;
}>();
</script>

<template>
  <ion-modal :is-open="isOpen" @didDismiss="isOpen = false">
    <ion-header>
      <ion-toolbar>
        <ion-title>Passendes Ereignis gefunden</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="isOpen = false">
            <ion-icon :icon="close"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div class="p-4">
        <div class="flex flex-col items-center gap-4">
          <b class="text-center">
            Wir haben ein Spielereignis gefunden,
            <br />
            welches zu deinem Highlight passen könnte:
          </b>
          <match-event-pictogram :element="targetCard.elements[0]" :event-type="targetCard.event" />
          Möchtest du dein Highlight an diese Story hängen?
        </div>
        <div class="flex flex-col gap-4 mt-4">
          <button class="default-button" @click="emit('select', targetCard)">Ja</button>
          <button class="default-button" @click="emit('decline')">Nein</button>
        </div>
      </div>
    </ion-content>
  </ion-modal>
</template>

<style lang="scss" scoped></style>
