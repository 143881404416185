<template>
  <div class="ghost-writer">
    <!--TASKBAR-->
    <div class="clear">
      <div class="clickAnimation" @click="clearTextareaContent()">
        <ion-icon :icon="close"></ion-icon>
      </div>
      <div class="clickAnimation" @click="undoLastChange">
        <ion-icon :icon="arrowUndo"></ion-icon>
      </div>
    </div>
    <!--KI GENERATED CONTENT-->
    <div
      class="gold-shimmer-wrapper"
      :class="{ 'inactive pointer-events-none': !canGenerateKI }"
      @click="addGeneratedContent"
    >
      <ion-icon :icon="hardwareChipOutline" :class="{ spinning: generateNewContent }"></ion-icon>
      <div class="gold-shimmer"></div>
    </div>
    <!--EDITABLE TEXTAREA-->
    <textarea
      ref="textarea"
      v-model="textareaContent"
      @input="onTextareaInput"
      rows="4"
      class="ghost-area"
      :class="{ darken: generateNewContent }"
      autofocus
    >
    </textarea>
    <div class="ghost-writer-bottom">
      <!--MAIN PILLS-->
      <div class="w-full pill-categorie" :class="{ 'pointer-events-none': generateNewContent }" v-if="!showPillResults">
        <div class="ion-icon-box">
          <ion-icon class="mb-0" :icon="flash"></ion-icon>
        </div>
        <div class="pill-wrapper">
          <div
            v-if="eventMessages.length > 0"
            @click="handlePillClick('generic')"
            @mousedown="$event.preventDefault()"
            class="pill default-categorie"
          >
            <ion-icon :icon="flame" />Aktion
          </div>
          <div
            v-if="selectedPlayer.length > 0"
            @click="handlePillClick('player')"
            @mousedown="$event.preventDefault()"
            class="pill player-categorie"
          >
            <ion-icon :icon="body" />Spieler
          </div>
          <div
            v-if="selectedMinute"
            @click="handlePillClick('time')"
            @mousedown="$event.preventDefault()"
            class="pill time-categorie"
          >
            <ion-icon :icon="time" />Minute
          </div>
          <div
            v-if="selectedTeamInfo"
            @click="handlePillClick('club')"
            @mousedown="$event.preventDefault()"
            class="pill club-categorie"
          >
            <ion-icon :icon="business"></ion-icon>Club
          </div>
          <div
            v-if="venueInfo"
            @click="handlePillClick('city')"
            @mousedown="$event.preventDefault()"
            class="pill city-categorie"
          >
            <ion-icon :icon="pin" />Orte
          </div>
        </div>
      </div>
      <ghost-writer-pills
        category="player"
        v-show="showPlayerResults"
        color="#F95738"
        :content="selectedPlayer"
        @closePillResults="closePillResults"
        @updateTextareaContent="updateTextareaContent"
      />
      <ghost-writer-pills
        category="minute"
        v-show="showTimeResults"
        v-if="selectedMinute"
        color="#F6BD60"
        :content="selectedMinute"
        @closePillResults="closePillResults"
        @updateTextareaContent="updateTextareaContent"
      />
      <ghost-writer-pills
        category="clubs"
        v-show="showClubResults"
        v-if="selectedTeamInfo"
        color="#1bbb76"
        :content="selectedTeamInfo"
        @closePillResults="closePillResults"
        @updateTextareaContent="updateTextareaContent"
      />
      <ghost-writer-pills
        category="venue"
        v-show="showCityResults"
        v-if="venueInfo"
        color="#8378ef"
        :content="venueInfo"
        @closePillResults="closePillResults"
        @updateTextareaContent="updateTextareaContent"
      />
      <ghost-writer-pills
        category="event"
        v-show="showGenericResults"
        v-if="eventMessages"
        color="#00d4d6"
        :content="eventMessages"
        :layerPill="useLayerPill()"
        @closePillResults="closePillResults"
        @updateTextareaContent="updateTextareaContent"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import * as signalR from '@microsoft/signalr';
import { computed, onMounted, ref, watch } from 'vue';
import { arrowUndo, body, business, close, flame, flash, hardwareChipOutline, pin, time } from 'ionicons/icons';
import { IonIcon } from '@ionic/vue';
import { MatchEventCard } from '@/models/match-event-card';
import { useAuthStore } from '@/store/auth';
import { useAppStore } from '@/store/app';
import GhostWriterPills, { type VenueInfo } from '@/components/GhostWriter/GhostWriterPills.vue';
import apiRequest from '@/services/apiRequest';
import { useGhostWriter } from '@/store/ghostWriter';
import { storeToRefs } from 'pinia';

const props = defineProps<{
  cardReference?: MatchEventCard;
}>();

const emit = defineEmits<{ (e: 'change', value: string): unknown }>();

type PillType = 'player' | 'time' | 'club' | 'city' | 'generic';

let connection: signalR.HubConnection;

const authStore = useAuthStore();

const ghostWriterStore = useGhostWriter();

const canGenerateKI = computed(() => textareaContent.value !== '' && typeof connection !== 'undefined');

const loyal = computed(() => {
  if (!props.cardReference?.elements || !('teamId' in props.cardReference.elements[0])) {
    return false;
  }
  const my = authStore.userSettings.selectedTimeline.uid;
  return my === props.cardReference.elements[0]?.teamId;
});
const { homeTeam, guestTeam } = storeToRefs(ghostWriterStore);
const homeOrGuest = computed<'home' | 'guest' | null>(() => {
  if (!props.cardReference) return null;
  const element = props.cardReference.elements[0];
  const teamIdSelected = authStore.userSettings.selectedTimeline.uid;
  if (!element) return null;
  if ('homeId' in element && 'guestId' in element) {
    const homeId = element.homeId || homeTeam.value?.uid;
    const guestId = element.guestId || guestTeam.value?.uid;

    if (teamIdSelected === homeId) return 'home';
    if (teamIdSelected === guestId) return 'guest';
  }
  if ('teamId' in element) {
    const teamId = element.teamId;
    if (teamId === teamIdSelected) return 'home';
  }
  return null;
});
const getFixtureInfos = async (eventEntityId: string) => {
  const response = await apiRequest.methods.get(`api/data/fixtures/${eventEntityId}/unfold`, 'cloud');
  if (response.data && response.data.venue) {
    ghostWriterStore.setVenue(response.data.venue);
  }
  if (response.data.homeTeam) {
    ghostWriterStore.setHomeTeam({
      uid: response.data.homeTeam.uid,
      acronym: response.data.homeTeam.acronym,
      name: response.data.homeTeam.name,
      fullName: response.data.homeTeam.fullName,
    });
  }
  if (response.data.guestTeam) {
    ghostWriterStore.setGuestTeam({
      uid: response.data.guestTeam.uid,
      acronym: response.data.guestTeam.acronym,
      name: response.data.guestTeam.name,
      fullName: response.data.guestTeam.fullName,
    });
  }
};

const getEventInfos = async () => {
  const eventEntityId = props?.cardReference?.properties?.eventEntityId;
  if (!eventEntityId) {
    return;
  }
  const response = await apiRequest.methods.get(`api/data/matchevents/${eventEntityId}`, 'cloud');
  if (response.data.fixtureUid !== ghostWriterStore.fixtureUid) {
    await getFixtureInfos(response.data.fixtureUid);
    ghostWriterStore.setFixtureUid(response.data.fixtureUid);
  }
  await sendCaptionMatchEvent([response.data.fixtureUid, eventEntityId, homeOrGuest.value, '']);
};

const sendCaptionMatchEvent = async (eventArgs: [string, string, string | null, string]) => {
  try {
    await connection.invoke('CaptionMatchEvent', ...eventArgs);
  } catch (err) {
    console.error('Fehler beim Senden der CaptionMatchEvent Nachricht: ', err);
  }
};

const sendCaptionVideo = async (eventArgs: [string | undefined, string]) => {
  try {
    await connection.invoke('CaptionVideo', ...eventArgs);
  } catch (err) {
    console.error('Fehler beim Senden der CaptionVideo Nachricht: ', err);
  }
};

const setKiProposal = ref(false);
const kiPropsals = ref([] as string[]);
const registerEventHandlers = () =>
  connection.on('Caption', (message: string) => {
    if (!setKiProposal.value) {
      updateTextareaContent(message, true);
      setKiProposal.value = true;
      generateNewContent.value = false;
    }
    kiPropsals.value.push(message);
  });

const startConnection = async () => {
  const appStore = useAppStore();
  const environment = appStore.environment;

  let hubUrl = `wss://supersub-${environment}.leagues.network/hubs/creator`;
  if (props?.cardReference?.properties?.eventEntityId) {
    hubUrl = `wss://supersub-${environment}.leagues.network/hubs/matchreporter`;
  }

  connection = new signalR.HubConnectionBuilder()
    .withUrl(hubUrl, {
      skipNegotiation: true,
      transport: signalR.HttpTransportType.WebSockets,
    })
    .configureLogging(signalR.LogLevel.Information)
    .withAutomaticReconnect()
    .build();

  try {
    await connection.start();
    console.log('Verbindung erfolgreich hergestellt.');
    const message = { protocol: 'json', version: 1 };
    await connection.send('Send', JSON.stringify(message) + '\u001E');
    registerEventHandlers();
  } catch (err) {
    console.error('Fehler beim Herstellen der Verbindung: ', err);
  }
};

onMounted(async () => {
  await startConnection();
  if (props.cardReference) {
    await getEventInfos();
  }
});

const venueInfo = computed<VenueInfo | undefined>(() => {
  const venueData = ghostWriterStore.venue;
  if (venueData) {
    const { name, cityName, address } = venueData;
    return { name, cityName, address };
  }
  return undefined;
});

const selectedPlayer = computed(() => {
  if (!props.cardReference?.elements) {
    return [];
  }
  const player = props.cardReference.elements[0];

  const result = [] as string[];
  const processPlayer = (playerText?: string, playerNumber?: number) => {
    if (playerText) {
      const fullName = playerText;
      const parts = fullName.split(' ');
      result.push(fullName, ...parts);

      if (playerNumber) {
        const lastNameWithNumber = `${parts[parts.length - 1]}#${playerNumber}`;
        const numberOnly = `#${playerNumber}`;
        result.push(lastNameWithNumber, numberOnly);
      }
    } else if (playerNumber) {
      const numberOnly = `#${playerNumber}`;
      result.push(numberOnly);
    }
  };

  if ('playerText' in player) {
    processPlayer(player.playerText, player.playerNumber);
  }
  if ('assistText' in player) {
    //@ts-expect-error This match type is not declared in the models yet
    processPlayer(player.assistText, player.assistNumber);
  }
  return result;
});

const selectedMinute = computed(() => {
  if (!props.cardReference?.elements) {
    return undefined;
  }

  const elem = props.cardReference.elements[0];

  if (!('minute' in elem)) {
    return;
  }

  const minute = elem.minute;
  const minuteAdditional = elem.minuteAdditional;
  const result: string[] = [];

  if (minute) {
    if (minute > 90) {
      result.push('kurz vor dem Abpfiff');
    } else if (minute > 43 && minute < 46) {
      result.push('kurz vor der Pause');
    }
    if (minuteAdditional > 0) {
      const fullMinute = minute + minuteAdditional;
      result.push(
        `${minute}+${minuteAdditional}`,
        `${fullMinute}.`,
        `${fullMinute}. Minute`,
        `${minuteAdditional}. Minute der Nachspielzeit`,
        `In der Nachspielzeit`,
      );
    } else {
      result.push(`${minute}. Minute`, `${minute}'`, `in der ${minute}. Minute`, `ab der ${minute}. Minute`);
    }
  }
  return result;
});

const selectedTeamInfo = computed(() => {
  if (!props.cardReference?.elements) {
    return undefined;
  }
  const element = props.cardReference.elements[0];
  const result: string[] = [];

  // Zugriff auf Store-Daten
  const { homeTeam, guestTeam } = ghostWriterStore;

  // Extrahiere Daten aus dem Element, wenn vorhanden
  const teamText = element && 'teamText' in element ? element.teamText : undefined;

  // Funktion zum Hinzufügen von Team-Infos in das Ergebnisarray
  const addTeamInfo = (team: Exclude<typeof homeTeam | typeof guestTeam, undefined>, isMainTeam = false) => {
    if (team.name && team.acronym) {
      // Überprüfe, ob name und fullName nicht identisch sind
      if (team.name !== team.fullName) {
        result.push(team.name, team.fullName, team.acronym);
      } else {
        result.push(team.name, team.acronym);
      }
    }

    // Wenn das Team das Hauptteam ist, stelle es an den Anfang des Arrays
    if (isMainTeam && result.length > 0) {
      const mainTeamInfo = result.splice(-2, 2); // Entferne die letzten zwei Einträge
      result.unshift(...mainTeamInfo); // Füge sie am Anfang ein
    }
  };

  // Überprüfe, ob das teamText dem homeTeam oder guestTeam entspricht und füge entsprechend hinzu
  if (teamText && homeTeam && guestTeam) {
    if (teamText === homeTeam?.name || teamText === homeTeam?.fullName) {
      addTeamInfo(homeTeam, true); // Hauptteam
      addTeamInfo(guestTeam);
    } else if (teamText === guestTeam?.name || teamText === guestTeam?.fullName) {
      addTeamInfo(guestTeam, true); // Hauptteam
      addTeamInfo(homeTeam);
    }
  } else {
    return undefined;
  }

  return result;
});
const useLayerPill = () => {
  return (
    (!props.cardReference && actionNoPropertySelect.value == '') || props?.cardReference?.elements[0]?.$type === 'Video'
  );
};

const eventMessages = computed(() => {
  let title = '';
  if (!props.cardReference || (props && props?.cardReference?.elements[0]?.$type === 'Video')) {
    if (actionNoPropertySelect.value === 'Gelbe Karte') {
      title = 'MatchYellowCard';
    } else if (actionNoPropertySelect.value === 'Rote Karte') {
      title = 'MatchRedCard';
    } else if (actionNoPropertySelect.value === 'Gelb Rote Karte') {
      title = 'MatchYellowRedCard';
    } else if (actionNoPropertySelect.value === 'Tor') {
      return ['Toooor!!! ⚽️', 'Was für ein Traumtor! 🌟', 'Da ist endlich das Tor! 🥅'];
    } else if (actionNoPropertySelect.value === 'Sonstiges') {
      return ['Das Stadion ist voll 🏟️', 'Top-Stimmung im Fanblock 📣'];
    } else if (actionNoPropertySelect.value === 'Torchance') {
      return ['Chance! 🎯', 'Was für eine Gelegenheit! 🤞', 'Knapp vorbei! 💨'];
    } else if (actionNoPropertySelect.value === 'Foul') {
      return ['Oh, das war ein Foul! 🚩', 'Das Spiel wird rauer! ⚠️', 'Foulspiel, wird das geahndet? 🤔'];
    } else if (actionNoPropertySelect.value === 'Einwechslung') {
      return [
        'Frische Beine auf dem Feld! 🔁',
        'Einwechslung, kann das das Spiel drehen? 🔄',
        'Neue Energie kommt ins Spiel! 💪',
      ];
    } else if (actionNoPropertySelect.value == '') {
      return [...actionNoPropertyID];
    } else {
      return [...actionNoPropertyID];
    }
  }
  if (title === '') {
    title = props?.cardReference?.properties.title || '';
  }
  let messages: string[] = ghostWriterStore.events[title] || [];
  let newMessage = '';

  if (props.cardReference) {
    const elem = props.cardReference.elements[0];

    if (elem) {
      if ('ownGoal' in elem && elem.ownGoal) {
        newMessage = 'Eigentor! 🙈';
      } else if ('penalty' in elem && elem.penalty) {
        newMessage = 'Elfmeter! ⚽';
      } else if (elem.$type === 'MatchGoal') {
        const who = homeOrGuest.value;
        const homeScore = elem?.homeScore;
        const guestScore = elem?.guestScore;
        let diff = 0;
        if (who === 'home') {
          diff = homeScore - guestScore;
        } else if (who === 'guest') {
          diff = guestScore - homeScore;
        }
        if (diff === 0) {
          newMessage = 'Ausgleich! ⚖️';
        } else if (diff === -1) {
          newMessage = loyal.value ? 'Gegentor! Aufholen! 💪' : 'Anschlusstreffer! Jetzt dranbleiben! 💪';
        } else if (diff > 0) {
          if (loyal.value) {
            newMessage = diff <= 3 ? 'Wir haben die Führung! ⚽🎉' : 'Was für eine Dominanz! 🚀🔥';
          } else {
            newMessage = diff <= 3 ? 'Wir liegen zurück! 😟' : 'Großer Rückstand! Zeit für ein Wunder... 🙏';
          }
        } else if (diff < 0) {
          if (loyal.value) {
            newMessage =
              diff == -2 ? "Jetzt wird's kritisch, wir müssen aufholen! 😟" : 'Das sieht nicht gut aus... 🙈';
          } else {
            newMessage = diff == -2 ? 'Sie holen auf! Bleibt wachsam! 👀' : 'Wir bauen unseren Vorsprung aus! 🚀';
          }
        }
      }
    }
  }

  if (newMessage !== '') {
    messages = [newMessage, ...messages];
  }
  if (messages.length === 0) {
    messages = [...actionNoPropertyID];
  }
  return messages;
});

//**
// KEVINS FUNKTIONEN:
// ***///

// Reaktive Zustände mit ref()
const showPillResults = ref(false);
const showPlayerResults = ref(false);
const showTimeResults = ref(false);
const showClubResults = ref(false);
const showCityResults = ref(false);
const showGenericResults = ref(false);

const textareaContent = ref('');
watch(textareaContent, (newValue) => emit('change', newValue));
const textChanged = ref(false);
watch(textareaContent, (newValue, oldValue) => {
  if (oldValue !== '') {
    textChanged.value = newValue !== newKiPropsals.value;
  }
});
const textarea = ref<null | HTMLTextAreaElement>(null);

const textareaHistory = ref([] as string[]);
// Funktionen
const handlePillClick = (pillType: PillType) => {
  showPillResults.value = true;

  showPlayerResults.value = false;
  showTimeResults.value = false;
  showClubResults.value = false;
  showCityResults.value = false;
  showGenericResults.value = false;

  switch (pillType) {
    case 'player':
      showPlayerResults.value = true;
      break;
    case 'time':
      showTimeResults.value = true;
      break;
    case 'club':
      showClubResults.value = true;
      break;
    case 'city':
      showCityResults.value = true;
      break;
    case 'generic':
      showGenericResults.value = true;
      break;
  }
};

const closePillResults = () => {
  showPillResults.value = false;
  actionListNoPropertyID.value = false;
  actionNoPropertySelect.value = '';
  showPlayerResults.value = false;
  showTimeResults.value = false;
  showClubResults.value = false;
  showCityResults.value = false;
  showGenericResults.value = false;
};

const actionListNoPropertyID = ref(false);
const actionNoPropertyID = [
  'Gelbe Karte',
  'Rote Karte',
  'Gelb Rote Karte',
  'Torchance',
  'Tor',
  'Wechsel',
  'Sonstiges',
  'Einwechslung',
] as const;
const actionNoPropertySelect = ref('');
const updateTextareaContent = (event: string, reset = false) => {
  if (!event) {
    return;
  }

  if (
    (!props.cardReference && actionNoPropertyID.includes(event)) ||
    (props && props?.cardReference?.elements[0]?.$type === 'Video' && actionNoPropertyID.includes(event))
  ) {
    actionListNoPropertyID.value = true;
    actionNoPropertySelect.value = event;
    return;
  }
  saveCurrentState();
  if (!reset) {
    textareaContent.value += ' ' + event;
  } else {
    textareaContent.value = event;
  }
  showPlayerResults.value = false;
  showTimeResults.value = false;
  showClubResults.value = false;
  showCityResults.value = false;
  showGenericResults.value = false;
  showPillResults.value = false;
};

const clearTextareaContent = () => {
  textareaContent.value = '';
};

const undoLastChange = () => {
  if (textareaHistory.value.length > 1) {
    const previousContent = textareaHistory.value.pop();
    if (typeof previousContent === 'string') {
      textareaContent.value = previousContent;
    } else {
      removeLastWord();
    }
  }
};

const removeLastWord = () => {
  const words = textareaContent.value.trim().split(' ');
  if (words.length > 1) {
    words.pop();
    textareaContent.value = words.join(' ');
  } else {
    textareaContent.value = '';
  }
};

const onTextareaInput = (event: Event) => {
  const target = event.target as HTMLTextAreaElement;
  if (target.value.slice(-1) === ' ') {
    saveCurrentState();
  }
};

const saveCurrentState = () => textareaHistory.value.push(textareaContent.value);

const newKiPropsals = ref('');
const generateNewContent = ref(false);
const addGeneratedContent = async () => {
  if (!canGenerateKI.value) {
    return;
  }
  if (kiPropsals.value.length > 0 && !textChanged.value) {
    saveCurrentState();
    const lastKiProposal = kiPropsals.value.pop();
    if (lastKiProposal) {
      newKiPropsals.value = lastKiProposal;
      textareaContent.value = lastKiProposal;
    }
  } else {
    setKiProposal.value = false;
    generateNewContent.value = true;
    if (props?.cardReference?.properties?.eventEntityId) {
      await sendCaptionMatchEvent([
        ghostWriterStore.fixtureUid,
        props?.cardReference?.properties?.eventEntityId,
        homeOrGuest.value,
        textareaContent.value,
      ]);
    } else {
      await sendCaptionVideo([authStore.userSettings.selectedTimeline.name, textareaContent.value]);
    }
  }
};
</script>

<style lang="scss">
/*.none {
  display: none;
}*/

.ghost-writer {
  margin-top: 10px;
  position: relative;
  margin-bottom: 1rem;

  .clear {
    position: absolute;
    bottom: 65px;
    right: 7px;
    display: flex;
    gap: 10px;
    flex-direction: column-reverse;

    ion-icon {
      font-size: 1.25rem;
      margin-bottom: 0;
    }
  }

  .clickAnimation {
    cursor: pointer;
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      border-radius: 2em;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      background: white;
      transition: all 0.6s;
      box-shadow: 0 0 2.5px 10px white;
    }

    &:active {
      &:after {
        box-shadow: 0 0 0 0 white;
        position: absolute;
        border-radius: 1em;
        left: 0;
        top: 0;
        opacity: 0.2;
        transition: 0s;
      }
    }
  }

  .clear {
    position: absolute;
    bottom: 65px;
    right: 7px;
    display: flex;
    gap: 10px;
    flex-direction: column-reverse;

    ion-icon {
      font-size: 1.25rem;
      margin-bottom: 0;
    }
  }

  .ghost-area {
    width: 100%;
    border-radius: 9px;
    padding: 0.5rem 25px 0.5rem 0.5rem;
    resize: none;
    background: #3b3b3b;
    border-bottom: 45px solid #3b3b3b;
    font-size: 21px;
    box-shadow:
      #00000029 0 3px 6px inset,
      #0000003b 0 3px 6px inset;
    transition: 0.25s ease;
  }

  .gold-shimmer-wrapper {
    user-select: none;
    cursor: pointer;
    margin-bottom: 0;
    padding: 0.25rem;
    width: 44px;
    height: 44px;
    background: #2c2c2c;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -8px;
    top: -8px;
    z-index: 1;
    /*background: #ffab2e;*/
    opacity: 0.8;
    transition: all ease-in-out 0.2s;
    border: 4px solid #191e24;

    background: radial-gradient(
        ellipse farthest-corner at right bottom,
        #fedb37 0%,
        #fdb931 8%,
        #9f7928 30%,
        #8a6e2f 40%,
        transparent 80%
      ),
      radial-gradient(
        ellipse farthest-corner at left top,
        #ffffff 0%,
        #ffffac 8%,
        #d1b464 25%,
        #5d4a1f 62.5%,
        #5d4a1f 100%
      );

    &.inactive {
      opacity: 0.7;
      background: radial-gradient(ellipse farthest-corner at right bottom, #e4e4e4 0%, #cfcfcf 50%, #b8b8b8 100%),
        radial-gradient(
          ellipse farthest-corner at left top,
          #f0f0f0 0%,
          /* Ein sehr helles Grau, leicht unterschiedlich zum anderen Startpunkt */ #dedede 50%,
          /* Ein mittleres Grau, passend zur Mitte des anderen Verlaufs */ #a8a8a8 100%
            /* Ein dunkleres Grau, das mit dem Rand des anderen Verlaufs harmoniert */
        );
    }

    ion-icon {
      margin-bottom: 0;
      font-size: 2rem;
    }

    .gold-shimmer {
      position: absolute;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.3);
      transform: skewX(-30deg);
      animation-name: gold-shimmer;
      animation-duration: 3s;
      animation-timing-function: ease-in-out;
      animation-delay: 0.3s;
      animation-iteration-count: infinite;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.03) 0%,
        rgba(255, 255, 255, 0.13) 77%,
        rgba(255, 255, 255, 0.5) 92%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }

  .ghost-writer-bottom {
    position: absolute;
    bottom: 9px;
    display: flex;
    width: 100%;
    align-items: center;
    border-bottom-left-radius: 9px;
    box-shadow: #0000003d 0px -3px 12px;
    padding-top: 8px;
    padding-bottom: 5px;
    overflow: hidden;
    padding-left: 10px;

    .pill-wrapper {
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .ion-icon-box {
      position: absolute;
      /*background: linear-gradient(90deg, rgba(59, 59, 59, 1) 85%, #00000000 110%);*/
      width: 40px;
      height: 100%;
      text-align: center;
      z-index: 1;

      ion-icon {
        font-size: 1.25rem;
      }
    }

    .pill-results {
      width: 100%;
      display: flex;
      background: #3b3b3b;
      animation: fade-in;
      animation-duration: 0.5s;
    }

    .pill-results--layer {
      .pill {
        position: relative;
        padding-right: 26px !important;

        &:after {
          content: '+';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 5px;
          background: #454545;
          width: 18px;
          height: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
        }
      }
    }

    .player-results {
      .pill-wrapper {
        .pill {
          border: 1px solid #f95738;
        }
      }
    }

    .time-results {
      .pill-wrapper {
        .pill {
          border: 1px solid #f6bd60;
        }
      }
    }

    .club-results {
      .pill-wrapper {
        .pill {
          border: 1px solid #1bbb76;
        }
      }
    }

    .city-results {
      .pill-wrapper {
        .pill {
          border: 1px solid #8378ef;
        }
      }
    }

    .generic-results {
      .pill-wrapper {
        .pill {
          border: 1px solid #00d4d6;
        }
      }
    }
  }

  .pill-categorie {
    animation: fade-in;
    animation-duration: 0.5s;
  }

  .pill-wrapper {
    display: flex;
    gap: 5px;
    overflow: scroll;
    margin-left: 40px;
    width: 100%;
    scroll-snap-type: x mandatory;

    .pill {
      cursor: pointer;
      scroll-snap-align: start;
      display: flex;
      background: #00000040;
      align-items: center;
      padding: 1px 10px;
      border-radius: 1rem;
      gap: 5px;
      white-space: nowrap;
      border: 1px solid #888;

      &:nth-last-of-type(1) {
        margin-right: 40px;
      }

      ion-icon {
        margin-bottom: 0;
      }
    }

    .player-categorie {
      border: 1px solid #f95738;

      ion-icon {
        fill: #f95738;
      }
    }

    .time-categorie {
      border: 1px solid #f6bd60;

      ion-icon {
        fill: #f6bd60;
      }
    }

    .club-categorie {
      border: 1px solid #1bbb76;

      ion-icon {
        fill: #1bbb76;
      }
    }

    .city-categorie {
      border: 1px solid #8378ef;

      ion-icon {
        fill: #8378ef;
      }
    }

    .default-categorie {
      border: 1px solid #00d4d6;

      ion-icon {
        fill: #00d4d6;
      }
    }
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
    margin-bottom: -7px;
  }

  100% {
    opacity: 1;
    margin-bottom: 0;
  }
}

@keyframes gold-shimmer {
  0% {
    left: -200%;
    top: 0;
  }

  100% {
    left: 200%;
    top: 0;
  }
}
</style>
<style scoped lang="scss">
//Loading KI Animation
.spinning {
  animation: loading-spinner 0.5s ease-in-out infinite;
}

.darken {
  filter: brightness(0.5);
  pointer-events: none;
}

@keyframes loading-spinner {
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.pointer-events-none,
.pointer-events-none * {
  pointer-events: none !important;
}
</style>
