import apiRequest from '@/services/apiRequest';
import type { AudioElement, ImageElement, VideoElement } from '@/models/media-element';
import type { PickedFile } from '@capawesome/capacitor-file-picker';
import { Capacitor } from '@capacitor/core';
import type { AxiosRequestConfig } from 'axios';
import nativeFileSystem from '@/services/nativeFileSystem';
import {
  getFileExtensionForMimeType,
  getFileKindFromMimeType,
  WEB_ALLOWED_VIDEO_MIME_TYPES,
} from '@/services/filePicker/mime-types';
import { usePreprocessingQueue } from '@leagues-football/upload-queue';

type CardID = string;

export type MediaElementPayload =
  | Pick<AudioElement, '$type' | 'audioUrl' | 'headline'>
  | Pick<VideoElement, '$type' | 'videoUrl' | 'headline'>
  | Pick<ImageElement, '$type' | 'imageUrl' | 'headline'>;

const uploadFilesV2Service = {
  async createBlobFromNativeFilePath(inputFilePath: string) {
    const fileURI = Capacitor.convertFileSrc(inputFilePath);

    const response = await fetch(fileURI);

    return await response.blob();
  },
  getFilename(mimeType: string): string {
    return `file${getFileExtensionForMimeType(mimeType)}`;
  },
  isNativeUpload(file: PickedFile): file is PickedFile & { path: string } {
    return !file.blob && !!file.path && Capacitor.isNativePlatform();
  },
  isVideo(file: PickedFile) {
    return !!file.mimeType && file.mimeType.startsWith('video/');
  },
  requiresCompression(file: PickedFile) {
    return this.isVideo(file) && this.isNativeUpload(file);
  },
  async enqueueCompressionTask(file: PickedFile): Promise<string> {
    if (!file.path) {
      throw Error('File path is not defined');
    }

    // Videos können nicht konvertiert werden, wenn der Dateiname ein Leerzeichen enthält
    let filePath = `${file.path}`;

    const decodedFileName = decodeURIComponent(filePath);

    const containsSpace = decodedFileName.includes(' ');
    if (containsSpace) {
      filePath = String(await nativeFileSystem.renameFile(filePath));
    }

    return usePreprocessingQueue().enqueue(filePath);
  },
  validateFileToUpload(file: PickedFile | File): [true, undefined] | [false, string] {
    const isWeb = !Capacitor.isNativePlatform();

    if (isWeb) {
      const MAX_FILE_SIZE_MB = 35;
      if (file.size > 1024 * 1024 * MAX_FILE_SIZE_MB) {
        return [
          false,
          `Diese Datei ist zu groß. Die maximale Dateigröße beträgt ${MAX_FILE_SIZE_MB} MB.\n` +
            'Nutze unsere App um dieses Video hochzuladen, oder komprimiere es selbst.',
        ];
      }
    }

    if (
      isWeb &&
      'mimeType' in file &&
      getFileKindFromMimeType(file.mimeType) === 'video' &&
      !WEB_ALLOWED_VIDEO_MIME_TYPES.includes(file.mimeType)
    ) {
      const allowed = WEB_ALLOWED_VIDEO_MIME_TYPES.join(', ');
      return [
        false,
        `Im Browser können wir momentan leider nur den Upload von ${allowed} Dateien anbieten 🥲\n` +
          'Nutze unsere App um dieses Video hochzuladen, oder konvertiere es selbst.',
      ];
    }

    return [true, undefined];
  },
  async uploadCardAsset(
    file: PickedFile | File,
    cardID: CardID,
    {
      onProgress,
      abortSignal,
    }: {
      onProgress?: AxiosRequestConfig['onUploadProgress'];
      abortSignal?: AbortSignal;
    } = {},
  ) {
    if (file instanceof File) {
      return await apiRequest.methods.postFile(`api/media/uploadCardAsset?cardId=${cardID}`, 'cloud', file, file.type, {
        onProgress,
        abortSignal,
      });
    } else {
      if (!file.blob) {
        throw Error('No blob exists');
      }

      const filename = this.getFilename(file.blob.type);

      return await apiRequest.methods.postMultipartFile<string>(
        `api/media/uploadCardAsset?cardId=${cardID}`,
        'cloud',
        file.blob,
        filename,
        { onProgress, abortSignal },
      );
    }
  },

  async uploadCloudFile(
    file: PickedFile,
    inboxId: string,
    {
      onProgress,
      abortSignal,
    }: {
      onProgress?: AxiosRequestConfig['onUploadProgress'];
      abortSignal?: AbortSignal;
    } = {},
  ) {
    if (!file.blob) {
      throw Error('No blob exists');
    }

    const filename = this.getFilename(file.blob.type);

    return await apiRequest.methods.postMultipartFile(
      `api/inbox/upload?inbox=${inboxId}`,
      'cloud',
      file.blob,
      filename,
      { onProgress, abortSignal },
    );
  },
};

export default uploadFilesV2Service;
