import { createApp, watch } from 'vue';
import App from './App.vue';
import router from './router';
import { QuillEditor } from '@vueup/vue-quill';

import { IonicVue } from '@ionic/vue';

import { emitter } from './bus';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
//import "@ionic/vue/css/structure.css";
//import "@ionic/vue/css/typography.css";
import './index.css';

// Import the global SCSS file
import './scss/global.scss';

/* Optional CSS utils that can be commented out */
//import "@ionic/vue/css/padding.css";
// import "@ionic/vue/css/float-elements.css";
//import "@ionic/vue/css/text-alignment.css";
//import "@ionic/vue/css/text-transformation.css";
//import "@ionic/vue/css/flex-utils.css";
//import "@ionic/vue/css/display.css";
/* Theme variables */
import './theme/variables.css';

// Import the AppIcon component
import AppIcon from './components/AppIcons.vue';

/* state management */
import { createPinia } from 'pinia';
import { initializeAuthListener } from '@/store/firebase';
import {
  NetworkStatus,
  useNetworkStatusStore,
  usePreprocessingQueue,
  useTaskQueue,
} from '@leagues-football/upload-queue';
import apiRequest from '@/services/apiRequest';
import { useAppStore } from '@/store/app';
import { VideoEditor } from '@awesome-cordova-plugins/video-editor';
import piniaPluginReset from '@/utils/pinia-utils';
import { VueQueryPlugin } from '@tanstack/vue-query';

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    emitter: typeof emitter;
  }
}

const pinia = createPinia();
pinia.use(piniaPluginReset);
const app = createApp(App).use(IonicVue).use(VueQueryPlugin).use(pinia);

// Only here for demonstration purposes. If it annoys you, feel free to remove it!
useTaskQueue().emitter.on('*', console.log);
usePreprocessingQueue().emitter.on('*', console.log);

usePreprocessingQueue().preprocessingCallback = async (task) =>
  await VideoEditor.transcodeVideo({
    fileUri: task.filePath,
    outputFileName: task.id,
    outputFileType: VideoEditor.OutputFileType.MPEG4, // android is always mp4
    optimizeForNetworkUse: VideoEditor.OptimizeForNetworkUse.YES, // ios only
    saveToLibrary: false,
    maintainAspectRatio: true, // optional (ios only), defaults to true
    width: 540, // optional, see note below on width and height
    height: 960, // optional, see notes below on width and height
    fps: 30, // optional (android only), defaults to 30
    videoBitrate: 1920000, // optional, bitrate in bits, defaults to 9 megabit (9000000)
    audioChannels: 2, // optional, number of audio channels, defaults: iOS - 2, Android - as is
    audioSampleRate: 44100, // optional (ios only), sample rate for the audio, defaults to 44100
    audioBitrate: 96000, // optional, audio bitrate for the video in bits,  defaults: iOS - 128000 (128 kilobits), Android - as is or 128000
    progress: (value: number) => task.setProgress(value),
  });

const taskQueue = useTaskQueue();
taskQueue.maxAutomaticRetries = 2;
taskQueue.maxParallelTasks = 2;

const initializeNetworkMonitoring = () => {
  const networkStatusStore = useNetworkStatusStore();
  networkStatusStore.pingTimeout = 29;
  networkStatusStore.pingInterval = 30;
  networkStatusStore.pingCallback = async (abortSignal) => {
    const response = await apiRequest.methods.get('keepalive', 'cloud', { abortSignal });
    if (response.error) {
      throw response.error;
    }
    return NetworkStatus.Online;
  };

  networkStatusStore.startNetworkMonitor();
};
initializeNetworkMonitoring();
watch(
  () => useAppStore().environment,
  () => initializeNetworkMonitoring(),
);

// - initialize auth listener to see if we have a user at startup
initializeAuthListener()
  .then(() => {
    app.use(router);
    return router.isReady();
  })
  .then(() => {
    // Register the AppIcon component globally
    app.component('app-icon', AppIcon);

    app.mount('#app');
  });

const quillGlobalOptions = {
  formats: ['bold', 'italic', 'underline', 'list'],
  modules: {
    toolbar: [['bold', 'italic', 'underline', { list: 'ordered' }, { list: 'bullet' }]],
  },
  clipboard: {
    matchVisual: false, // https://quilljs.com/docs/modules/clipboard/#matchvisual
  },
  placeholder: 'Fließtext',
  theme: 'snow',
} as const;
// set default globalOptions prop
QuillEditor.props.globalOptions.default = () => quillGlobalOptions;
// register QuillEditor component
app.component('QuillEditor', QuillEditor);
